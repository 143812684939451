/*-------- 24. Login register style ---------*/

.about-us-img {
    margin: 0 0 0 7px;
    img {
        width: 100%;
    }
}

.about-us-content {
    margin: 26px 0 0;
    @media #{$xs-layout} {
        margin: 0px 0 0;
    }
    h1 {
        font-size: 46px;
        line-height: 65px;
        margin: 0 0 24px;
        width: 75%;
        @media #{$xl-layout} {
            width: 80%;
        }
        @media #{$lg-layout} {
            font-size: 42px;
            line-height: 55px;
            width: 100%;
        }
        @media #{$md-layout} {
            font-size: 43px;
            line-height: 45px;
            margin: 0 0 15px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            line-height: 45px;
            margin: 0 0 15px;
            width: 100%;
        }
    }
    p {
        color: #6D6D6D;
        font-size: 18px;
        line-height: 36px;
        width: 90%;
        margin: 0;
        @media #{$xl-layout} {
            width: 100%;
        }
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 0 0 30px;
        }
        @media #{$xs-layout} {
            font-size: 17px;
            line-height: 32px;
            width: 100%;
            margin: 0 0 30px;
        }
    }
}

.skill-img {
    margin: 0 55px 0 0;
    @media #{$lg-layout} {
        margin: 0 0px 0 0;
    }
    @media #{$md-layout} {
        margin: 0 0px 20px 0;
    }
    @media #{$xs-layout} {
        margin: 0 0px 20px 0;
    }
    img {
        width: 100%;
    }
}

.skill-content {
    margin: 0 0 0 40px;
    @media #{$xl-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0 0 0px;
    }
    h2 {
        font-size: 46px;
        letter-spacing: -1.64px;
        margin: 0 0 27px;
        @media #{$md-layout} {
            margin: 0 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 34px;
            margin: 0 0 15px;
        }
    }
    p {
        font-size: 18px;
        line-height: 36px;
        margin: 0;
        color: #6D6D6D;
        @media #{$xs-layout} {
            font-size: 17px;
            line-height: 34px;
        }
    }
}

.skill-bar {
    overflow: hidden;
    padding-top: 50px;
    @media #{$lg-layout} {
        padding-top: 35px;
    }
    @media #{$md-layout} {
        padding-top: 35px;
    }
    @media #{$xs-layout} {
        padding-top: 30px;
    }
    .skill-bar-item {
        padding-bottom: 51px;
        position: relative;
        @media #{$lg-layout} {
            padding-bottom: 35px;
        }
        @media #{$md-layout} {
            padding-bottom: 35px;
        }
        @media #{$xs-layout} {
            padding-bottom: 30px;
        }
        &:last-child {
            padding-bottom: 0px;
        }
        > span {
            color: #6D6D6D;
            font-size: 18px;
            text-align: center;
            font-family: $proximanova;
            z-index: 2;
        }
        .progress {
            background: #D8D8D8 none repeat scroll 0 0;
            border-radius: 0;
            box-shadow: none;
            height: 2px;
            margin-top: 7px;
            overflow: visible;
            .progress-bar {
                background: #181818 none repeat scroll 0 0;
                box-shadow: none;
                overflow: inherit;
                position: relative;
                span.text-top {
                    color: #6D6D6D;
                    font-size: 18px;
                    position: absolute;
                    font-family: $proximanova;
                    right: 0;
                    top: -34px;
                }
            }
        }
        &:nth-child(1) .progress-bar {
            width: 90%;
        }
        &:nth-child(2) .progress-bar {
            width: 75%;
        }
        &:nth-child(3) .progress-bar {
            width: 90%;
        }
    }
}

.skill-area {
	margin: 232px 0 0;
    @media #{$xl-layout} {
        margin: 132px 0 0;
    }
    @media #{$lg-layout} {
        margin: 132px 0 0;
    }
    @media #{$md-layout} {
        margin: 100px 0 0;
    }
    @media #{$xs-layout} {
        margin: 70px 0 0;
    }
}
.team-area {
	margin: 217px 0 152px;
    @media #{$xl-layout} {
        margin: 117px 0 52px;
    }
    @media #{$lg-layout} {
        margin: 117px 0 52px;
    }
    @media #{$md-layout} {
        margin: 100px 0 52px;
    }
    @media #{$xs-layout} {
        margin: 70px 0 20px;
    }
}
.team-margin-wrap {
    margin: 0 51px;
    @media #{$lg-layout} {
        margin: 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

.team-wrap {
    .team-img {
        margin: 0 0 25px;
        overflow: hidden;
        @media #{$xs-layout} {
            margin: 0 0 20px;
        }
        img {
            width: 100%;
            transition: all .5s ease 0s;
        }
    }
    .team-content {
        h3 {
            font-size: 24px;
            letter-spacing: -0.86px;
            margin: 0 0 9px;
            line-height: 1;
        }
        span {
            display: block;
            color: $theme-color;
            font-size: 18px;
            letter-spacing: -0.64px;
            font-family: $proximanova;
        }
    }
    &:hover {
        .team-img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.padding-64-row-col {
    .row {
        margin-right: -32px;
        margin-left: -32px;
        @media #{$xl-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$lg-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$md-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$xs-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        div[class^="col-"] {
            padding-left: 32px;
            padding-right: 32px;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$md-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}





