/*-----------------------------------------------------------------------------------

    Template Name: New Wave - Minimal eCommerce HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header style
    03. Slider style
    04. Features style
    05. Section title style
    06. Product style
    07. Subscribe style
    08. Brand logo style
    09. Footer style
    10. Instagram style
    11. Breadcrumb style
    12. Sidebar style
    13. Contact us style
    14. Coming soon style
    15. About us style
    16. Blog style
    17. Product details style
    18. Cart style
    19. Wishlist style
    20. Login register style
    21. Checkout style
    22. My account style

-----------------------------------------------------------------------------------*/
/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Rozha+One&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #181818;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: unset;
  }
}

body {
  margin: 0;
  font-family: "Rozha One", serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #181818;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Rozha One", serif;
  font-weight: 400;
  line-height: 1.2;
  color: #181818;
}

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.1875rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.27812rem + 0.3375vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.53125rem;
  }
}

h4, .h4 {
  font-size: calc(1.25625rem + 0.075vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #181818;
  text-decoration: underline;
}

a:hover {
  color: #131313;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 3.4375rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 3.4375rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 3.75rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 3.75rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 4.0625rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 4.0625rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 4.375rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 4.375rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 4.375rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 4.375rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 4.375rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 4.375rem;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 4.375rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #181818;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #181818;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #181818;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #181818;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d1d1d1;
  --bs-table-striped-bg: #c7c7c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcbcbc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c1c1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcbcbc;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #484e53;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #181818;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #181818;
  background-color: #ffffff;
  border-color: #8c8c8c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #181818;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #181818;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #181818;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #181818;
  vertical-align: middle;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #8c8c8c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #181818;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #8c8c8c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.form-check-input:checked {
  background-color: #181818;
  border-color: #181818;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #181818;
  border-color: #181818;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238c8c8c'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #181818;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #bababa;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #181818;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #bababa;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #181818;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #ffffff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: #181818;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 17px 68px;
  font-size: calc(1.275rem + 0.3vw);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 1200px) {
  .btn {
    font-size: 1.5rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #181818;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #ffffff;
  background-color: #181818;
  border-color: #181818;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #141414;
  border-color: #131313;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #ffffff;
  background-color: #141414;
  border-color: #131313;
  box-shadow: 0 0 0 0.25rem rgba(59, 59, 59, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #131313;
  border-color: #121212;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(59, 59, 59, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #ffffff;
  background-color: #181818;
  border-color: #181818;
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #ffffff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #ffffff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #ffffff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #2c3136;
  border-color: #2a2e33;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #ffffff;
  background-color: #2c3136;
  border-color: #2a2e33;
  box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #2a2e33;
  border-color: #272c30;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 88, 93, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-primary {
  color: #181818;
  border-color: #181818;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #181818;
  border-color: #181818;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #181818;
  border-color: #181818;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #181818;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #181818;
  text-decoration: underline;
}

.btn-link:hover {
  color: #131313;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #181818;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[style] {
  right: auto !important;
}

.dropdown-menu-start {
  --bs-position: start;
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #181818;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #181818;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 51px;
  padding-left: 51px;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #181818;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem /* rtl:ignore */;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #181818;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #161616;
  background-color: #e8e8e8;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23161616'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181818'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #8c8c8c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #181818;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #131313;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #131313;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #181818;
  border-color: #181818;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #0e0e0e;
  background-color: #d1d1d1;
  border-color: #bababa;
}

.alert-primary .alert-link {
  color: #0b0b0b;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #1f2326;
  background-color: #d6d8d9;
  border-color: #c2c4c6;
}

.alert-dark .alert-link {
  color: #191c1e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #181818;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #181818;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #181818;
  border-color: #181818;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0e0e0e;
  background-color: #d1d1d1;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0e0e0e;
  background-color: #bcbcbc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1f2326;
  background-color: #c1c2c3;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2326;
  border-color: #1f2326;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rozha One", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rozha One", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
  margin-bottom: 0.5rem !important;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
  margin-left: 0.5rem !important;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
  margin-top: 0.5rem !important;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
  margin-right: 0.5rem !important;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #181818;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #181818;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators li {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #181818;
}

.link-primary:hover, .link-primary:focus {
  color: #131313;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}

.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #343a40;
}

.link-dark:hover, .link-dark:focus {
  color: #2a2e33;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #181818 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 0.9375rem !important;
}

.gap-4 {
  gap: 1.25rem !important;
}

.gap-5 {
  gap: 1.625rem !important;
}

.gap-6 {
  gap: 1.875rem !important;
}

.gap-7 {
  gap: 2.1875rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 2.8125rem !important;
}

.gap-10 {
  gap: 3.125rem !important;
}

.gap-11 {
  gap: 3.4375rem !important;
}

.gap-12 {
  gap: 3.75rem !important;
}

.gap-13 {
  gap: 4.0625rem !important;
}

.gap-14 {
  gap: 4.375rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.625rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.m-11 {
  margin: 3.4375rem !important;
}

.m-12 {
  margin: 3.75rem !important;
}

.m-13 {
  margin: 4.0625rem !important;
}

.m-14 {
  margin: 4.375rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.625rem !important;
  margin-left: 1.625rem !important;
}

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important;
}

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-11 {
  margin-right: 3.4375rem !important;
  margin-left: 3.4375rem !important;
}

.mx-12 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-13 {
  margin-right: 4.0625rem !important;
  margin-left: 4.0625rem !important;
}

.mx-14 {
  margin-right: 4.375rem !important;
  margin-left: 4.375rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-11 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important;
}

.my-12 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-13 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important;
}

.my-14 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.mt-11 {
  margin-top: 3.4375rem !important;
}

.mt-12 {
  margin-top: 3.75rem !important;
}

.mt-13 {
  margin-top: 4.0625rem !important;
}

.mt-14 {
  margin-top: 4.375rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 0.9375rem !important;
}

.me-4 {
  margin-right: 1.25rem !important;
}

.me-5 {
  margin-right: 1.625rem !important;
}

.me-6 {
  margin-right: 1.875rem !important;
}

.me-7 {
  margin-right: 2.1875rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 2.8125rem !important;
}

.me-10 {
  margin-right: 3.125rem !important;
}

.me-11 {
  margin-right: 3.4375rem !important;
}

.me-12 {
  margin-right: 3.75rem !important;
}

.me-13 {
  margin-right: 4.0625rem !important;
}

.me-14 {
  margin-right: 4.375rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.625rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.mb-11 {
  margin-bottom: 3.4375rem !important;
}

.mb-12 {
  margin-bottom: 3.75rem !important;
}

.mb-13 {
  margin-bottom: 4.0625rem !important;
}

.mb-14 {
  margin-bottom: 4.375rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 0.9375rem !important;
}

.ms-4 {
  margin-left: 1.25rem !important;
}

.ms-5 {
  margin-left: 1.625rem !important;
}

.ms-6 {
  margin-left: 1.875rem !important;
}

.ms-7 {
  margin-left: 2.1875rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 2.8125rem !important;
}

.ms-10 {
  margin-left: 3.125rem !important;
}

.ms-11 {
  margin-left: 3.4375rem !important;
}

.ms-12 {
  margin-left: 3.75rem !important;
}

.ms-13 {
  margin-left: 4.0625rem !important;
}

.ms-14 {
  margin-left: 4.375rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.625rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.m-n7 {
  margin: -2.1875rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -2.8125rem !important;
}

.m-n10 {
  margin: -3.125rem !important;
}

.m-n11 {
  margin: -3.4375rem !important;
}

.m-n12 {
  margin: -3.75rem !important;
}

.m-n13 {
  margin: -4.0625rem !important;
}

.m-n14 {
  margin: -4.375rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.625rem !important;
  margin-left: -1.625rem !important;
}

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important;
}

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.mx-n11 {
  margin-right: -3.4375rem !important;
  margin-left: -3.4375rem !important;
}

.mx-n12 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n13 {
  margin-right: -4.0625rem !important;
  margin-left: -4.0625rem !important;
}

.mx-n14 {
  margin-right: -4.375rem !important;
  margin-left: -4.375rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important;
}

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.my-n11 {
  margin-top: -3.4375rem !important;
  margin-bottom: -3.4375rem !important;
}

.my-n12 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n13 {
  margin-top: -4.0625rem !important;
  margin-bottom: -4.0625rem !important;
}

.my-n14 {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.9375rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.625rem !important;
}

.mt-n6 {
  margin-top: -1.875rem !important;
}

.mt-n7 {
  margin-top: -2.1875rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -2.8125rem !important;
}

.mt-n10 {
  margin-top: -3.125rem !important;
}

.mt-n11 {
  margin-top: -3.4375rem !important;
}

.mt-n12 {
  margin-top: -3.75rem !important;
}

.mt-n13 {
  margin-top: -4.0625rem !important;
}

.mt-n14 {
  margin-top: -4.375rem !important;
}

.me-n1 {
  margin-right: -0.3125rem !important;
}

.me-n2 {
  margin-right: -0.625rem !important;
}

.me-n3 {
  margin-right: -0.9375rem !important;
}

.me-n4 {
  margin-right: -1.25rem !important;
}

.me-n5 {
  margin-right: -1.625rem !important;
}

.me-n6 {
  margin-right: -1.875rem !important;
}

.me-n7 {
  margin-right: -2.1875rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -2.8125rem !important;
}

.me-n10 {
  margin-right: -3.125rem !important;
}

.me-n11 {
  margin-right: -3.4375rem !important;
}

.me-n12 {
  margin-right: -3.75rem !important;
}

.me-n13 {
  margin-right: -4.0625rem !important;
}

.me-n14 {
  margin-right: -4.375rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.9375rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.625rem !important;
}

.mb-n6 {
  margin-bottom: -1.875rem !important;
}

.mb-n7 {
  margin-bottom: -2.1875rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -2.8125rem !important;
}

.mb-n10 {
  margin-bottom: -3.125rem !important;
}

.mb-n11 {
  margin-bottom: -3.4375rem !important;
}

.mb-n12 {
  margin-bottom: -3.75rem !important;
}

.mb-n13 {
  margin-bottom: -4.0625rem !important;
}

.mb-n14 {
  margin-bottom: -4.375rem !important;
}

.ms-n1 {
  margin-left: -0.3125rem !important;
}

.ms-n2 {
  margin-left: -0.625rem !important;
}

.ms-n3 {
  margin-left: -0.9375rem !important;
}

.ms-n4 {
  margin-left: -1.25rem !important;
}

.ms-n5 {
  margin-left: -1.625rem !important;
}

.ms-n6 {
  margin-left: -1.875rem !important;
}

.ms-n7 {
  margin-left: -2.1875rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -2.8125rem !important;
}

.ms-n10 {
  margin-left: -3.125rem !important;
}

.ms-n11 {
  margin-left: -3.4375rem !important;
}

.ms-n12 {
  margin-left: -3.75rem !important;
}

.ms-n13 {
  margin-left: -4.0625rem !important;
}

.ms-n14 {
  margin-left: -4.375rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.625rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.p-11 {
  padding: 3.4375rem !important;
}

.p-12 {
  padding: 3.75rem !important;
}

.p-13 {
  padding: 4.0625rem !important;
}

.p-14 {
  padding: 4.375rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.625rem !important;
  padding-left: 1.625rem !important;
}

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important;
}

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.px-11 {
  padding-right: 3.4375rem !important;
  padding-left: 3.4375rem !important;
}

.px-12 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-13 {
  padding-right: 4.0625rem !important;
  padding-left: 4.0625rem !important;
}

.px-14 {
  padding-right: 4.375rem !important;
  padding-left: 4.375rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.py-11 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important;
}

.py-12 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-13 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important;
}

.py-14 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.625rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.pt-11 {
  padding-top: 3.4375rem !important;
}

.pt-12 {
  padding-top: 3.75rem !important;
}

.pt-13 {
  padding-top: 4.0625rem !important;
}

.pt-14 {
  padding-top: 4.375rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 0.9375rem !important;
}

.pe-4 {
  padding-right: 1.25rem !important;
}

.pe-5 {
  padding-right: 1.625rem !important;
}

.pe-6 {
  padding-right: 1.875rem !important;
}

.pe-7 {
  padding-right: 2.1875rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 2.8125rem !important;
}

.pe-10 {
  padding-right: 3.125rem !important;
}

.pe-11 {
  padding-right: 3.4375rem !important;
}

.pe-12 {
  padding-right: 3.75rem !important;
}

.pe-13 {
  padding-right: 4.0625rem !important;
}

.pe-14 {
  padding-right: 4.375rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.625rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.pb-11 {
  padding-bottom: 3.4375rem !important;
}

.pb-12 {
  padding-bottom: 3.75rem !important;
}

.pb-13 {
  padding-bottom: 4.0625rem !important;
}

.pb-14 {
  padding-bottom: 4.375rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 0.9375rem !important;
}

.ps-4 {
  padding-left: 1.25rem !important;
}

.ps-5 {
  padding-left: 1.625rem !important;
}

.ps-6 {
  padding-left: 1.875rem !important;
}

.ps-7 {
  padding-left: 2.1875rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 2.8125rem !important;
}

.ps-10 {
  padding-left: 3.125rem !important;
}

.ps-11 {
  padding-left: 3.4375rem !important;
}

.ps-12 {
  padding-left: 3.75rem !important;
}

.ps-13 {
  padding-left: 4.0625rem !important;
}

.ps-14 {
  padding-left: 4.375rem !important;
}

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.27812rem + 0.3375vw) !important;
}

.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-5 {
  font-size: 1.09375rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #181818 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #343a40 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-body {
  color: #181818 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #181818 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.bg-body {
  background-color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.3125rem !important;
  }
  .gap-sm-2 {
    gap: 0.625rem !important;
  }
  .gap-sm-3 {
    gap: 0.9375rem !important;
  }
  .gap-sm-4 {
    gap: 1.25rem !important;
  }
  .gap-sm-5 {
    gap: 1.625rem !important;
  }
  .gap-sm-6 {
    gap: 1.875rem !important;
  }
  .gap-sm-7 {
    gap: 2.1875rem !important;
  }
  .gap-sm-8 {
    gap: 2.5rem !important;
  }
  .gap-sm-9 {
    gap: 2.8125rem !important;
  }
  .gap-sm-10 {
    gap: 3.125rem !important;
  }
  .gap-sm-11 {
    gap: 3.4375rem !important;
  }
  .gap-sm-12 {
    gap: 3.75rem !important;
  }
  .gap-sm-13 {
    gap: 4.0625rem !important;
  }
  .gap-sm-14 {
    gap: 4.375rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.625rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.1875rem !important;
  }
  .m-sm-8 {
    margin: 2.5rem !important;
  }
  .m-sm-9 {
    margin: 2.8125rem !important;
  }
  .m-sm-10 {
    margin: 3.125rem !important;
  }
  .m-sm-11 {
    margin: 3.4375rem !important;
  }
  .m-sm-12 {
    margin: 3.75rem !important;
  }
  .m-sm-13 {
    margin: 4.0625rem !important;
  }
  .m-sm-14 {
    margin: 4.375rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important;
  }
  .mx-sm-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important;
  }
  .mx-sm-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important;
  }
  .my-sm-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important;
  }
  .my-sm-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.625rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-sm-10 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-11 {
    margin-top: 3.4375rem !important;
  }
  .mt-sm-12 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-13 {
    margin-top: 4.0625rem !important;
  }
  .mt-sm-14 {
    margin-top: 4.375rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .me-sm-2 {
    margin-right: 0.625rem !important;
  }
  .me-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .me-sm-4 {
    margin-right: 1.25rem !important;
  }
  .me-sm-5 {
    margin-right: 1.625rem !important;
  }
  .me-sm-6 {
    margin-right: 1.875rem !important;
  }
  .me-sm-7 {
    margin-right: 2.1875rem !important;
  }
  .me-sm-8 {
    margin-right: 2.5rem !important;
  }
  .me-sm-9 {
    margin-right: 2.8125rem !important;
  }
  .me-sm-10 {
    margin-right: 3.125rem !important;
  }
  .me-sm-11 {
    margin-right: 3.4375rem !important;
  }
  .me-sm-12 {
    margin-right: 3.75rem !important;
  }
  .me-sm-13 {
    margin-right: 4.0625rem !important;
  }
  .me-sm-14 {
    margin-right: 4.375rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 3.4375rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 4.0625rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 4.375rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.625rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-sm-10 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-11 {
    margin-left: 3.4375rem !important;
  }
  .ms-sm-12 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-13 {
    margin-left: 4.0625rem !important;
  }
  .ms-sm-14 {
    margin-left: 4.375rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.625rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.1875rem !important;
  }
  .m-sm-n8 {
    margin: -2.5rem !important;
  }
  .m-sm-n9 {
    margin: -2.8125rem !important;
  }
  .m-sm-n10 {
    margin: -3.125rem !important;
  }
  .m-sm-n11 {
    margin: -3.4375rem !important;
  }
  .m-sm-n12 {
    margin: -3.75rem !important;
  }
  .m-sm-n13 {
    margin: -4.0625rem !important;
  }
  .m-sm-n14 {
    margin: -4.375rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-sm-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important;
  }
  .mx-sm-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-sm-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important;
  }
  .mx-sm-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-sm-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important;
  }
  .my-sm-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-sm-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important;
  }
  .my-sm-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3.125rem !important;
  }
  .mt-sm-n11 {
    margin-top: -3.4375rem !important;
  }
  .mt-sm-n12 {
    margin-top: -3.75rem !important;
  }
  .mt-sm-n13 {
    margin-top: -4.0625rem !important;
  }
  .mt-sm-n14 {
    margin-top: -4.375rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.625rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-sm-n10 {
    margin-right: -3.125rem !important;
  }
  .me-sm-n11 {
    margin-right: -3.4375rem !important;
  }
  .me-sm-n12 {
    margin-right: -3.75rem !important;
  }
  .me-sm-n13 {
    margin-right: -4.0625rem !important;
  }
  .me-sm-n14 {
    margin-right: -4.375rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -3.4375rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -3.75rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -4.0625rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -4.375rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3.125rem !important;
  }
  .ms-sm-n11 {
    margin-left: -3.4375rem !important;
  }
  .ms-sm-n12 {
    margin-left: -3.75rem !important;
  }
  .ms-sm-n13 {
    margin-left: -4.0625rem !important;
  }
  .ms-sm-n14 {
    margin-left: -4.375rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.625rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.1875rem !important;
  }
  .p-sm-8 {
    padding: 2.5rem !important;
  }
  .p-sm-9 {
    padding: 2.8125rem !important;
  }
  .p-sm-10 {
    padding: 3.125rem !important;
  }
  .p-sm-11 {
    padding: 3.4375rem !important;
  }
  .p-sm-12 {
    padding: 3.75rem !important;
  }
  .p-sm-13 {
    padding: 4.0625rem !important;
  }
  .p-sm-14 {
    padding: 4.375rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-sm-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important;
  }
  .px-sm-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important;
  }
  .px-sm-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-sm-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important;
  }
  .py-sm-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .py-sm-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.625rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-sm-10 {
    padding-top: 3.125rem !important;
  }
  .pt-sm-11 {
    padding-top: 3.4375rem !important;
  }
  .pt-sm-12 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-13 {
    padding-top: 4.0625rem !important;
  }
  .pt-sm-14 {
    padding-top: 4.375rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.625rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-sm-10 {
    padding-right: 3.125rem !important;
  }
  .pe-sm-11 {
    padding-right: 3.4375rem !important;
  }
  .pe-sm-12 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-13 {
    padding-right: 4.0625rem !important;
  }
  .pe-sm-14 {
    padding-right: 4.375rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 3.4375rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 4.0625rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 4.375rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.625rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-sm-10 {
    padding-left: 3.125rem !important;
  }
  .ps-sm-11 {
    padding-left: 3.4375rem !important;
  }
  .ps-sm-12 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-13 {
    padding-left: 4.0625rem !important;
  }
  .ps-sm-14 {
    padding-left: 4.375rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.3125rem !important;
  }
  .gap-md-2 {
    gap: 0.625rem !important;
  }
  .gap-md-3 {
    gap: 0.9375rem !important;
  }
  .gap-md-4 {
    gap: 1.25rem !important;
  }
  .gap-md-5 {
    gap: 1.625rem !important;
  }
  .gap-md-6 {
    gap: 1.875rem !important;
  }
  .gap-md-7 {
    gap: 2.1875rem !important;
  }
  .gap-md-8 {
    gap: 2.5rem !important;
  }
  .gap-md-9 {
    gap: 2.8125rem !important;
  }
  .gap-md-10 {
    gap: 3.125rem !important;
  }
  .gap-md-11 {
    gap: 3.4375rem !important;
  }
  .gap-md-12 {
    gap: 3.75rem !important;
  }
  .gap-md-13 {
    gap: 4.0625rem !important;
  }
  .gap-md-14 {
    gap: 4.375rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.625rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.1875rem !important;
  }
  .m-md-8 {
    margin: 2.5rem !important;
  }
  .m-md-9 {
    margin: 2.8125rem !important;
  }
  .m-md-10 {
    margin: 3.125rem !important;
  }
  .m-md-11 {
    margin: 3.4375rem !important;
  }
  .m-md-12 {
    margin: 3.75rem !important;
  }
  .m-md-13 {
    margin: 4.0625rem !important;
  }
  .m-md-14 {
    margin: 4.375rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important;
  }
  .mx-md-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important;
  }
  .mx-md-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important;
  }
  .my-md-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important;
  }
  .my-md-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-4 {
    margin-top: 1.25rem !important;
  }
  .mt-md-5 {
    margin-top: 1.625rem !important;
  }
  .mt-md-6 {
    margin-top: 1.875rem !important;
  }
  .mt-md-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-md-8 {
    margin-top: 2.5rem !important;
  }
  .mt-md-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-md-10 {
    margin-top: 3.125rem !important;
  }
  .mt-md-11 {
    margin-top: 3.4375rem !important;
  }
  .mt-md-12 {
    margin-top: 3.75rem !important;
  }
  .mt-md-13 {
    margin-top: 4.0625rem !important;
  }
  .mt-md-14 {
    margin-top: 4.375rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.3125rem !important;
  }
  .me-md-2 {
    margin-right: 0.625rem !important;
  }
  .me-md-3 {
    margin-right: 0.9375rem !important;
  }
  .me-md-4 {
    margin-right: 1.25rem !important;
  }
  .me-md-5 {
    margin-right: 1.625rem !important;
  }
  .me-md-6 {
    margin-right: 1.875rem !important;
  }
  .me-md-7 {
    margin-right: 2.1875rem !important;
  }
  .me-md-8 {
    margin-right: 2.5rem !important;
  }
  .me-md-9 {
    margin-right: 2.8125rem !important;
  }
  .me-md-10 {
    margin-right: 3.125rem !important;
  }
  .me-md-11 {
    margin-right: 3.4375rem !important;
  }
  .me-md-12 {
    margin-right: 3.75rem !important;
  }
  .me-md-13 {
    margin-right: 4.0625rem !important;
  }
  .me-md-14 {
    margin-right: 4.375rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-11 {
    margin-bottom: 3.4375rem !important;
  }
  .mb-md-12 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-13 {
    margin-bottom: 4.0625rem !important;
  }
  .mb-md-14 {
    margin-bottom: 4.375rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-md-2 {
    margin-left: 0.625rem !important;
  }
  .ms-md-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-md-4 {
    margin-left: 1.25rem !important;
  }
  .ms-md-5 {
    margin-left: 1.625rem !important;
  }
  .ms-md-6 {
    margin-left: 1.875rem !important;
  }
  .ms-md-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-md-8 {
    margin-left: 2.5rem !important;
  }
  .ms-md-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-md-10 {
    margin-left: 3.125rem !important;
  }
  .ms-md-11 {
    margin-left: 3.4375rem !important;
  }
  .ms-md-12 {
    margin-left: 3.75rem !important;
  }
  .ms-md-13 {
    margin-left: 4.0625rem !important;
  }
  .ms-md-14 {
    margin-left: 4.375rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.625rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.1875rem !important;
  }
  .m-md-n8 {
    margin: -2.5rem !important;
  }
  .m-md-n9 {
    margin: -2.8125rem !important;
  }
  .m-md-n10 {
    margin: -3.125rem !important;
  }
  .m-md-n11 {
    margin: -3.4375rem !important;
  }
  .m-md-n12 {
    margin: -3.75rem !important;
  }
  .m-md-n13 {
    margin: -4.0625rem !important;
  }
  .m-md-n14 {
    margin: -4.375rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-md-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important;
  }
  .mx-md-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-md-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important;
  }
  .mx-md-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-md-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important;
  }
  .my-md-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-md-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important;
  }
  .my-md-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-md-n10 {
    margin-top: -3.125rem !important;
  }
  .mt-md-n11 {
    margin-top: -3.4375rem !important;
  }
  .mt-md-n12 {
    margin-top: -3.75rem !important;
  }
  .mt-md-n13 {
    margin-top: -4.0625rem !important;
  }
  .mt-md-n14 {
    margin-top: -4.375rem !important;
  }
  .me-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-md-n2 {
    margin-right: -0.625rem !important;
  }
  .me-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-md-n4 {
    margin-right: -1.25rem !important;
  }
  .me-md-n5 {
    margin-right: -1.625rem !important;
  }
  .me-md-n6 {
    margin-right: -1.875rem !important;
  }
  .me-md-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-md-n8 {
    margin-right: -2.5rem !important;
  }
  .me-md-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-md-n10 {
    margin-right: -3.125rem !important;
  }
  .me-md-n11 {
    margin-right: -3.4375rem !important;
  }
  .me-md-n12 {
    margin-right: -3.75rem !important;
  }
  .me-md-n13 {
    margin-right: -4.0625rem !important;
  }
  .me-md-n14 {
    margin-right: -4.375rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -3.4375rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -3.75rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -4.0625rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -4.375rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-md-n10 {
    margin-left: -3.125rem !important;
  }
  .ms-md-n11 {
    margin-left: -3.4375rem !important;
  }
  .ms-md-n12 {
    margin-left: -3.75rem !important;
  }
  .ms-md-n13 {
    margin-left: -4.0625rem !important;
  }
  .ms-md-n14 {
    margin-left: -4.375rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.625rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.1875rem !important;
  }
  .p-md-8 {
    padding: 2.5rem !important;
  }
  .p-md-9 {
    padding: 2.8125rem !important;
  }
  .p-md-10 {
    padding: 3.125rem !important;
  }
  .p-md-11 {
    padding: 3.4375rem !important;
  }
  .p-md-12 {
    padding: 3.75rem !important;
  }
  .p-md-13 {
    padding: 4.0625rem !important;
  }
  .p-md-14 {
    padding: 4.375rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-md-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important;
  }
  .px-md-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important;
  }
  .px-md-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-md-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important;
  }
  .py-md-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .py-md-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-4 {
    padding-top: 1.25rem !important;
  }
  .pt-md-5 {
    padding-top: 1.625rem !important;
  }
  .pt-md-6 {
    padding-top: 1.875rem !important;
  }
  .pt-md-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-md-8 {
    padding-top: 2.5rem !important;
  }
  .pt-md-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-md-10 {
    padding-top: 3.125rem !important;
  }
  .pt-md-11 {
    padding-top: 3.4375rem !important;
  }
  .pt-md-12 {
    padding-top: 3.75rem !important;
  }
  .pt-md-13 {
    padding-top: 4.0625rem !important;
  }
  .pt-md-14 {
    padding-top: 4.375rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-md-2 {
    padding-right: 0.625rem !important;
  }
  .pe-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-md-4 {
    padding-right: 1.25rem !important;
  }
  .pe-md-5 {
    padding-right: 1.625rem !important;
  }
  .pe-md-6 {
    padding-right: 1.875rem !important;
  }
  .pe-md-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-md-8 {
    padding-right: 2.5rem !important;
  }
  .pe-md-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-md-10 {
    padding-right: 3.125rem !important;
  }
  .pe-md-11 {
    padding-right: 3.4375rem !important;
  }
  .pe-md-12 {
    padding-right: 3.75rem !important;
  }
  .pe-md-13 {
    padding-right: 4.0625rem !important;
  }
  .pe-md-14 {
    padding-right: 4.375rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem !important;
  }
  .pb-md-11 {
    padding-bottom: 3.4375rem !important;
  }
  .pb-md-12 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-13 {
    padding-bottom: 4.0625rem !important;
  }
  .pb-md-14 {
    padding-bottom: 4.375rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-md-2 {
    padding-left: 0.625rem !important;
  }
  .ps-md-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-md-4 {
    padding-left: 1.25rem !important;
  }
  .ps-md-5 {
    padding-left: 1.625rem !important;
  }
  .ps-md-6 {
    padding-left: 1.875rem !important;
  }
  .ps-md-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-md-8 {
    padding-left: 2.5rem !important;
  }
  .ps-md-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-md-10 {
    padding-left: 3.125rem !important;
  }
  .ps-md-11 {
    padding-left: 3.4375rem !important;
  }
  .ps-md-12 {
    padding-left: 3.75rem !important;
  }
  .ps-md-13 {
    padding-left: 4.0625rem !important;
  }
  .ps-md-14 {
    padding-left: 4.375rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.3125rem !important;
  }
  .gap-lg-2 {
    gap: 0.625rem !important;
  }
  .gap-lg-3 {
    gap: 0.9375rem !important;
  }
  .gap-lg-4 {
    gap: 1.25rem !important;
  }
  .gap-lg-5 {
    gap: 1.625rem !important;
  }
  .gap-lg-6 {
    gap: 1.875rem !important;
  }
  .gap-lg-7 {
    gap: 2.1875rem !important;
  }
  .gap-lg-8 {
    gap: 2.5rem !important;
  }
  .gap-lg-9 {
    gap: 2.8125rem !important;
  }
  .gap-lg-10 {
    gap: 3.125rem !important;
  }
  .gap-lg-11 {
    gap: 3.4375rem !important;
  }
  .gap-lg-12 {
    gap: 3.75rem !important;
  }
  .gap-lg-13 {
    gap: 4.0625rem !important;
  }
  .gap-lg-14 {
    gap: 4.375rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.625rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.1875rem !important;
  }
  .m-lg-8 {
    margin: 2.5rem !important;
  }
  .m-lg-9 {
    margin: 2.8125rem !important;
  }
  .m-lg-10 {
    margin: 3.125rem !important;
  }
  .m-lg-11 {
    margin: 3.4375rem !important;
  }
  .m-lg-12 {
    margin: 3.75rem !important;
  }
  .m-lg-13 {
    margin: 4.0625rem !important;
  }
  .m-lg-14 {
    margin: 4.375rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important;
  }
  .mx-lg-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important;
  }
  .mx-lg-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important;
  }
  .my-lg-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important;
  }
  .my-lg-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.625rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-lg-10 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-11 {
    margin-top: 3.4375rem !important;
  }
  .mt-lg-12 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-13 {
    margin-top: 4.0625rem !important;
  }
  .mt-lg-14 {
    margin-top: 4.375rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .me-lg-2 {
    margin-right: 0.625rem !important;
  }
  .me-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .me-lg-4 {
    margin-right: 1.25rem !important;
  }
  .me-lg-5 {
    margin-right: 1.625rem !important;
  }
  .me-lg-6 {
    margin-right: 1.875rem !important;
  }
  .me-lg-7 {
    margin-right: 2.1875rem !important;
  }
  .me-lg-8 {
    margin-right: 2.5rem !important;
  }
  .me-lg-9 {
    margin-right: 2.8125rem !important;
  }
  .me-lg-10 {
    margin-right: 3.125rem !important;
  }
  .me-lg-11 {
    margin-right: 3.4375rem !important;
  }
  .me-lg-12 {
    margin-right: 3.75rem !important;
  }
  .me-lg-13 {
    margin-right: 4.0625rem !important;
  }
  .me-lg-14 {
    margin-right: 4.375rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 3.4375rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 4.0625rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 4.375rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.625rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-lg-10 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-11 {
    margin-left: 3.4375rem !important;
  }
  .ms-lg-12 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-13 {
    margin-left: 4.0625rem !important;
  }
  .ms-lg-14 {
    margin-left: 4.375rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.625rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.1875rem !important;
  }
  .m-lg-n8 {
    margin: -2.5rem !important;
  }
  .m-lg-n9 {
    margin: -2.8125rem !important;
  }
  .m-lg-n10 {
    margin: -3.125rem !important;
  }
  .m-lg-n11 {
    margin: -3.4375rem !important;
  }
  .m-lg-n12 {
    margin: -3.75rem !important;
  }
  .m-lg-n13 {
    margin: -4.0625rem !important;
  }
  .m-lg-n14 {
    margin: -4.375rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-lg-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important;
  }
  .mx-lg-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-lg-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important;
  }
  .mx-lg-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-lg-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important;
  }
  .my-lg-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-lg-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important;
  }
  .my-lg-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3.125rem !important;
  }
  .mt-lg-n11 {
    margin-top: -3.4375rem !important;
  }
  .mt-lg-n12 {
    margin-top: -3.75rem !important;
  }
  .mt-lg-n13 {
    margin-top: -4.0625rem !important;
  }
  .mt-lg-n14 {
    margin-top: -4.375rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.625rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-lg-n10 {
    margin-right: -3.125rem !important;
  }
  .me-lg-n11 {
    margin-right: -3.4375rem !important;
  }
  .me-lg-n12 {
    margin-right: -3.75rem !important;
  }
  .me-lg-n13 {
    margin-right: -4.0625rem !important;
  }
  .me-lg-n14 {
    margin-right: -4.375rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -3.4375rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -3.75rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -4.0625rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -4.375rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3.125rem !important;
  }
  .ms-lg-n11 {
    margin-left: -3.4375rem !important;
  }
  .ms-lg-n12 {
    margin-left: -3.75rem !important;
  }
  .ms-lg-n13 {
    margin-left: -4.0625rem !important;
  }
  .ms-lg-n14 {
    margin-left: -4.375rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.625rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.1875rem !important;
  }
  .p-lg-8 {
    padding: 2.5rem !important;
  }
  .p-lg-9 {
    padding: 2.8125rem !important;
  }
  .p-lg-10 {
    padding: 3.125rem !important;
  }
  .p-lg-11 {
    padding: 3.4375rem !important;
  }
  .p-lg-12 {
    padding: 3.75rem !important;
  }
  .p-lg-13 {
    padding: 4.0625rem !important;
  }
  .p-lg-14 {
    padding: 4.375rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-lg-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important;
  }
  .px-lg-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important;
  }
  .px-lg-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-lg-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important;
  }
  .py-lg-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .py-lg-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.625rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-lg-10 {
    padding-top: 3.125rem !important;
  }
  .pt-lg-11 {
    padding-top: 3.4375rem !important;
  }
  .pt-lg-12 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-13 {
    padding-top: 4.0625rem !important;
  }
  .pt-lg-14 {
    padding-top: 4.375rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.625rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-lg-10 {
    padding-right: 3.125rem !important;
  }
  .pe-lg-11 {
    padding-right: 3.4375rem !important;
  }
  .pe-lg-12 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-13 {
    padding-right: 4.0625rem !important;
  }
  .pe-lg-14 {
    padding-right: 4.375rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 3.4375rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 4.0625rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 4.375rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.625rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-lg-10 {
    padding-left: 3.125rem !important;
  }
  .ps-lg-11 {
    padding-left: 3.4375rem !important;
  }
  .ps-lg-12 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-13 {
    padding-left: 4.0625rem !important;
  }
  .ps-lg-14 {
    padding-left: 4.375rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xl-2 {
    gap: 0.625rem !important;
  }
  .gap-xl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xl-4 {
    gap: 1.25rem !important;
  }
  .gap-xl-5 {
    gap: 1.625rem !important;
  }
  .gap-xl-6 {
    gap: 1.875rem !important;
  }
  .gap-xl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xl-8 {
    gap: 2.5rem !important;
  }
  .gap-xl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xl-10 {
    gap: 3.125rem !important;
  }
  .gap-xl-11 {
    gap: 3.4375rem !important;
  }
  .gap-xl-12 {
    gap: 3.75rem !important;
  }
  .gap-xl-13 {
    gap: 4.0625rem !important;
  }
  .gap-xl-14 {
    gap: 4.375rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.625rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.1875rem !important;
  }
  .m-xl-8 {
    margin: 2.5rem !important;
  }
  .m-xl-9 {
    margin: 2.8125rem !important;
  }
  .m-xl-10 {
    margin: 3.125rem !important;
  }
  .m-xl-11 {
    margin: 3.4375rem !important;
  }
  .m-xl-12 {
    margin: 3.75rem !important;
  }
  .m-xl-13 {
    margin: 4.0625rem !important;
  }
  .m-xl-14 {
    margin: 4.375rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important;
  }
  .mx-xl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important;
  }
  .mx-xl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important;
  }
  .my-xl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important;
  }
  .my-xl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-11 {
    margin-top: 3.4375rem !important;
  }
  .mt-xl-12 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-13 {
    margin-top: 4.0625rem !important;
  }
  .mt-xl-14 {
    margin-top: 4.375rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xl-11 {
    margin-right: 3.4375rem !important;
  }
  .me-xl-12 {
    margin-right: 3.75rem !important;
  }
  .me-xl-13 {
    margin-right: 4.0625rem !important;
  }
  .me-xl-14 {
    margin-right: 4.375rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 3.4375rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 4.0625rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-11 {
    margin-left: 3.4375rem !important;
  }
  .ms-xl-12 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-13 {
    margin-left: 4.0625rem !important;
  }
  .ms-xl-14 {
    margin-left: 4.375rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.625rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xl-n8 {
    margin: -2.5rem !important;
  }
  .m-xl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xl-n10 {
    margin: -3.125rem !important;
  }
  .m-xl-n11 {
    margin: -3.4375rem !important;
  }
  .m-xl-n12 {
    margin: -3.75rem !important;
  }
  .m-xl-n13 {
    margin: -4.0625rem !important;
  }
  .m-xl-n14 {
    margin: -4.375rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-xl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important;
  }
  .mx-xl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important;
  }
  .mx-xl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-xl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important;
  }
  .my-xl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important;
  }
  .my-xl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3.125rem !important;
  }
  .mt-xl-n11 {
    margin-top: -3.4375rem !important;
  }
  .mt-xl-n12 {
    margin-top: -3.75rem !important;
  }
  .mt-xl-n13 {
    margin-top: -4.0625rem !important;
  }
  .mt-xl-n14 {
    margin-top: -4.375rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xl-n10 {
    margin-right: -3.125rem !important;
  }
  .me-xl-n11 {
    margin-right: -3.4375rem !important;
  }
  .me-xl-n12 {
    margin-right: -3.75rem !important;
  }
  .me-xl-n13 {
    margin-right: -4.0625rem !important;
  }
  .me-xl-n14 {
    margin-right: -4.375rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -3.4375rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -4.0625rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -4.375rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3.125rem !important;
  }
  .ms-xl-n11 {
    margin-left: -3.4375rem !important;
  }
  .ms-xl-n12 {
    margin-left: -3.75rem !important;
  }
  .ms-xl-n13 {
    margin-left: -4.0625rem !important;
  }
  .ms-xl-n14 {
    margin-left: -4.375rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.625rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.1875rem !important;
  }
  .p-xl-8 {
    padding: 2.5rem !important;
  }
  .p-xl-9 {
    padding: 2.8125rem !important;
  }
  .p-xl-10 {
    padding: 3.125rem !important;
  }
  .p-xl-11 {
    padding: 3.4375rem !important;
  }
  .p-xl-12 {
    padding: 3.75rem !important;
  }
  .p-xl-13 {
    padding: 4.0625rem !important;
  }
  .p-xl-14 {
    padding: 4.375rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-xl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important;
  }
  .px-xl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important;
  }
  .px-xl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-xl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important;
  }
  .py-xl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .py-xl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xl-10 {
    padding-top: 3.125rem !important;
  }
  .pt-xl-11 {
    padding-top: 3.4375rem !important;
  }
  .pt-xl-12 {
    padding-top: 3.75rem !important;
  }
  .pt-xl-13 {
    padding-top: 4.0625rem !important;
  }
  .pt-xl-14 {
    padding-top: 4.375rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xl-10 {
    padding-right: 3.125rem !important;
  }
  .pe-xl-11 {
    padding-right: 3.4375rem !important;
  }
  .pe-xl-12 {
    padding-right: 3.75rem !important;
  }
  .pe-xl-13 {
    padding-right: 4.0625rem !important;
  }
  .pe-xl-14 {
    padding-right: 4.375rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 3.4375rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 4.0625rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 4.375rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xl-10 {
    padding-left: 3.125rem !important;
  }
  .ps-xl-11 {
    padding-left: 3.4375rem !important;
  }
  .ps-xl-12 {
    padding-left: 3.75rem !important;
  }
  .ps-xl-13 {
    padding-left: 4.0625rem !important;
  }
  .ps-xl-14 {
    padding-left: 4.375rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xxl-2 {
    gap: 0.625rem !important;
  }
  .gap-xxl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xxl-4 {
    gap: 1.25rem !important;
  }
  .gap-xxl-5 {
    gap: 1.625rem !important;
  }
  .gap-xxl-6 {
    gap: 1.875rem !important;
  }
  .gap-xxl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xxl-8 {
    gap: 2.5rem !important;
  }
  .gap-xxl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xxl-10 {
    gap: 3.125rem !important;
  }
  .gap-xxl-11 {
    gap: 3.4375rem !important;
  }
  .gap-xxl-12 {
    gap: 3.75rem !important;
  }
  .gap-xxl-13 {
    gap: 4.0625rem !important;
  }
  .gap-xxl-14 {
    gap: 4.375rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 0.9375rem !important;
  }
  .m-xxl-4 {
    margin: 1.25rem !important;
  }
  .m-xxl-5 {
    margin: 1.625rem !important;
  }
  .m-xxl-6 {
    margin: 1.875rem !important;
  }
  .m-xxl-7 {
    margin: 2.1875rem !important;
  }
  .m-xxl-8 {
    margin: 2.5rem !important;
  }
  .m-xxl-9 {
    margin: 2.8125rem !important;
  }
  .m-xxl-10 {
    margin: 3.125rem !important;
  }
  .m-xxl-11 {
    margin: 3.4375rem !important;
  }
  .m-xxl-12 {
    margin: 3.75rem !important;
  }
  .m-xxl-13 {
    margin: 4.0625rem !important;
  }
  .m-xxl-14 {
    margin: 4.375rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-11 {
    margin-right: 3.4375rem !important;
    margin-left: 3.4375rem !important;
  }
  .mx-xxl-12 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-13 {
    margin-right: 4.0625rem !important;
    margin-left: 4.0625rem !important;
  }
  .mx-xxl-14 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-11 {
    margin-top: 3.4375rem !important;
    margin-bottom: 3.4375rem !important;
  }
  .my-xxl-12 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-13 {
    margin-top: 4.0625rem !important;
    margin-bottom: 4.0625rem !important;
  }
  .my-xxl-14 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-11 {
    margin-top: 3.4375rem !important;
  }
  .mt-xxl-12 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-13 {
    margin-top: 4.0625rem !important;
  }
  .mt-xxl-14 {
    margin-top: 4.375rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xxl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xxl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xxl-11 {
    margin-right: 3.4375rem !important;
  }
  .me-xxl-12 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-13 {
    margin-right: 4.0625rem !important;
  }
  .me-xxl-14 {
    margin-right: 4.375rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 3.4375rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 4.0625rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xxl-11 {
    margin-left: 3.4375rem !important;
  }
  .ms-xxl-12 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-13 {
    margin-left: 4.0625rem !important;
  }
  .ms-xxl-14 {
    margin-left: 4.375rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xxl-n2 {
    margin: -0.625rem !important;
  }
  .m-xxl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xxl-n4 {
    margin: -1.25rem !important;
  }
  .m-xxl-n5 {
    margin: -1.625rem !important;
  }
  .m-xxl-n6 {
    margin: -1.875rem !important;
  }
  .m-xxl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xxl-n8 {
    margin: -2.5rem !important;
  }
  .m-xxl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xxl-n10 {
    margin: -3.125rem !important;
  }
  .m-xxl-n11 {
    margin: -3.4375rem !important;
  }
  .m-xxl-n12 {
    margin: -3.75rem !important;
  }
  .m-xxl-n13 {
    margin: -4.0625rem !important;
  }
  .m-xxl-n14 {
    margin: -4.375rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -3.4375rem !important;
    margin-left: -3.4375rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xxl-n13 {
    margin-right: -4.0625rem !important;
    margin-left: -4.0625rem !important;
  }
  .mx-xxl-n14 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-xxl-n11 {
    margin-top: -3.4375rem !important;
    margin-bottom: -3.4375rem !important;
  }
  .my-xxl-n12 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xxl-n13 {
    margin-top: -4.0625rem !important;
    margin-bottom: -4.0625rem !important;
  }
  .my-xxl-n14 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -3.4375rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -3.75rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -4.0625rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -4.375rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xxl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3.125rem !important;
  }
  .me-xxl-n11 {
    margin-right: -3.4375rem !important;
  }
  .me-xxl-n12 {
    margin-right: -3.75rem !important;
  }
  .me-xxl-n13 {
    margin-right: -4.0625rem !important;
  }
  .me-xxl-n14 {
    margin-right: -4.375rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -3.4375rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -4.0625rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -4.375rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3.125rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -3.4375rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -3.75rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -4.0625rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -4.375rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 0.9375rem !important;
  }
  .p-xxl-4 {
    padding: 1.25rem !important;
  }
  .p-xxl-5 {
    padding: 1.625rem !important;
  }
  .p-xxl-6 {
    padding: 1.875rem !important;
  }
  .p-xxl-7 {
    padding: 2.1875rem !important;
  }
  .p-xxl-8 {
    padding: 2.5rem !important;
  }
  .p-xxl-9 {
    padding: 2.8125rem !important;
  }
  .p-xxl-10 {
    padding: 3.125rem !important;
  }
  .p-xxl-11 {
    padding: 3.4375rem !important;
  }
  .p-xxl-12 {
    padding: 3.75rem !important;
  }
  .p-xxl-13 {
    padding: 4.0625rem !important;
  }
  .p-xxl-14 {
    padding: 4.375rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-xxl-11 {
    padding-right: 3.4375rem !important;
    padding-left: 3.4375rem !important;
  }
  .px-xxl-12 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xxl-13 {
    padding-right: 4.0625rem !important;
    padding-left: 4.0625rem !important;
  }
  .px-xxl-14 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-xxl-11 {
    padding-top: 3.4375rem !important;
    padding-bottom: 3.4375rem !important;
  }
  .py-xxl-12 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xxl-13 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .py-xxl-14 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3.125rem !important;
  }
  .pt-xxl-11 {
    padding-top: 3.4375rem !important;
  }
  .pt-xxl-12 {
    padding-top: 3.75rem !important;
  }
  .pt-xxl-13 {
    padding-top: 4.0625rem !important;
  }
  .pt-xxl-14 {
    padding-top: 4.375rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3.125rem !important;
  }
  .pe-xxl-11 {
    padding-right: 3.4375rem !important;
  }
  .pe-xxl-12 {
    padding-right: 3.75rem !important;
  }
  .pe-xxl-13 {
    padding-right: 4.0625rem !important;
  }
  .pe-xxl-14 {
    padding-right: 4.375rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 3.4375rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 4.0625rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 4.375rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3.125rem !important;
  }
  .ps-xxl-11 {
    padding-left: 3.4375rem !important;
  }
  .ps-xxl-12 {
    padding-left: 3.75rem !important;
  }
  .ps-xxl-13 {
    padding-left: 4.0625rem !important;
  }
  .ps-xxl-14 {
    padding-left: 4.375rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.53125rem !important;
  }
  .fs-4 {
    font-size: 1.3125rem !important;
  }
  .fs-sm-1 {
    font-size: 2.1875rem !important;
  }
  .fs-sm-2 {
    font-size: 1.75rem !important;
  }
  .fs-sm-3 {
    font-size: 1.53125rem !important;
  }
  .fs-sm-4 {
    font-size: 1.3125rem !important;
  }
  .fs-md-1 {
    font-size: 2.1875rem !important;
  }
  .fs-md-2 {
    font-size: 1.75rem !important;
  }
  .fs-md-3 {
    font-size: 1.53125rem !important;
  }
  .fs-md-4 {
    font-size: 1.3125rem !important;
  }
  .fs-lg-1 {
    font-size: 2.1875rem !important;
  }
  .fs-lg-2 {
    font-size: 1.75rem !important;
  }
  .fs-lg-3 {
    font-size: 1.53125rem !important;
  }
  .fs-lg-4 {
    font-size: 1.3125rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  visibility: visible;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #8E8E8E;
  margin-bottom: 15px;
  font-family: "Proxima Nova Rg";
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

a,
button {
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #BB9B1F;
}

button,
input[type="submit"] {
  cursor: pointer;
}

.st-mb-50 {
  margin-bottom: 50px;
}

.st-mb-60 {
  margin-bottom: 60px;
}

.st-mb-145 {
  margin-bottom: 145px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .st-mb-145 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .st-mb-145 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .st-mb-145 {
    margin-bottom: 30px;
  }
}

.st-mb-150 {
  margin-bottom: 150px;
}

.st-mb-95 {
  margin-bottom: 95px;
}

.st-mb-90 {
  margin-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .st-mb-90 {
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .st-mb-90 {
    margin-bottom: 37px;
  }
}

.st-mb-120 {
  margin-bottom: 120px;
}

.st-mb-125 {
  margin-bottom: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .st-mb-125 {
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .st-mb-125 {
    margin-bottom: 37px;
  }
}

.st-mb-103 {
  margin-bottom: 103px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .st-mb-103 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .st-mb-103 {
    margin-bottom: 35px;
  }
}

.pt-130 {
  padding-top: 130px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-245 {
  padding-top: 245px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-145 {
  padding-top: 145px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-135 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 70px;
  }
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-175 {
  padding-bottom: 175px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-175 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-175 {
    padding-bottom: 70px;
  }
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-235 {
  padding-bottom: 235px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-235 {
    padding-bottom: 135px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-235 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-235 {
    padding-bottom: 70px;
  }
}

.mr-50 {
  margin-right: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-47 {
  margin-bottom: 47px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-120 {
  margin-bottom: 120px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.bg-gray {
  background-color: #f9f9f9;
}

.bg-gray-2 {
  background-color: #FBF9EE;
}

.bg-gray-3 {
  background-color: #E8ECF8;
}

.bg-granite {
  background-color: #ECE5E6;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.section-padding-lr-1 .container-fluid, .section-padding-lr-1 .container-sm, .section-padding-lr-1 .container-md, .section-padding-lr-1 .container-lg, .section-padding-lr-1 .container-xl, .section-padding-lr-1 .container-xxl {
  padding-left: 140px;
  padding-right: 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-lr-1 .container-fluid, .section-padding-lr-1 .container-sm, .section-padding-lr-1 .container-md, .section-padding-lr-1 .container-lg, .section-padding-lr-1 .container-xl, .section-padding-lr-1 .container-xxl {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-lr-1 .container-fluid, .section-padding-lr-1 .container-sm, .section-padding-lr-1 .container-md, .section-padding-lr-1 .container-lg, .section-padding-lr-1 .container-xl, .section-padding-lr-1 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-lr-1 .container-fluid, .section-padding-lr-1 .container-sm, .section-padding-lr-1 .container-md, .section-padding-lr-1 .container-lg, .section-padding-lr-1 .container-xl, .section-padding-lr-1 .container-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-lr-1 .container-fluid, .section-padding-lr-1 .container-sm, .section-padding-lr-1 .container-md, .section-padding-lr-1 .container-lg, .section-padding-lr-1 .container-xl, .section-padding-lr-1 .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-lr-1 .container-fluid, .section-padding-lr-1 .container-sm, .section-padding-lr-1 .container-md, .section-padding-lr-1 .container-lg, .section-padding-lr-1 .container-xl, .section-padding-lr-1 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-padding-lr-2 .container-fluid, .section-padding-lr-2 .container-sm, .section-padding-lr-2 .container-md, .section-padding-lr-2 .container-lg, .section-padding-lr-2 .container-xl, .section-padding-lr-2 .container-xxl {
  padding-left: 240px;
  padding-right: 240px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-lr-2 .container-fluid, .section-padding-lr-2 .container-sm, .section-padding-lr-2 .container-md, .section-padding-lr-2 .container-lg, .section-padding-lr-2 .container-xl, .section-padding-lr-2 .container-xxl {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-lr-2 .container-fluid, .section-padding-lr-2 .container-sm, .section-padding-lr-2 .container-md, .section-padding-lr-2 .container-lg, .section-padding-lr-2 .container-xl, .section-padding-lr-2 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-lr-2 .container-fluid, .section-padding-lr-2 .container-sm, .section-padding-lr-2 .container-md, .section-padding-lr-2 .container-lg, .section-padding-lr-2 .container-xl, .section-padding-lr-2 .container-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-lr-2 .container-fluid, .section-padding-lr-2 .container-sm, .section-padding-lr-2 .container-md, .section-padding-lr-2 .container-lg, .section-padding-lr-2 .container-xl, .section-padding-lr-2 .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-lr-2 .container-fluid, .section-padding-lr-2 .container-sm, .section-padding-lr-2 .container-md, .section-padding-lr-2 .container-lg, .section-padding-lr-2 .container-xl, .section-padding-lr-2 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-padding-lr-3 .container-fluid, .section-padding-lr-3 .container-sm, .section-padding-lr-3 .container-md, .section-padding-lr-3 .container-lg, .section-padding-lr-3 .container-xl, .section-padding-lr-3 .container-xxl {
  padding-left: 254px;
  padding-right: 254px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-lr-3 .container-fluid, .section-padding-lr-3 .container-sm, .section-padding-lr-3 .container-md, .section-padding-lr-3 .container-lg, .section-padding-lr-3 .container-xl, .section-padding-lr-3 .container-xxl {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-lr-3 .container-fluid, .section-padding-lr-3 .container-sm, .section-padding-lr-3 .container-md, .section-padding-lr-3 .container-lg, .section-padding-lr-3 .container-xl, .section-padding-lr-3 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-lr-3 .container-fluid, .section-padding-lr-3 .container-sm, .section-padding-lr-3 .container-md, .section-padding-lr-3 .container-lg, .section-padding-lr-3 .container-xl, .section-padding-lr-3 .container-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-lr-3 .container-fluid, .section-padding-lr-3 .container-sm, .section-padding-lr-3 .container-md, .section-padding-lr-3 .container-lg, .section-padding-lr-3 .container-xl, .section-padding-lr-3 .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-lr-3 .container-fluid, .section-padding-lr-3 .container-sm, .section-padding-lr-3 .container-md, .section-padding-lr-3 .container-lg, .section-padding-lr-3 .container-xl, .section-padding-lr-3 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-padding-lr-4 .container-fluid, .section-padding-lr-4 .container-sm, .section-padding-lr-4 .container-md, .section-padding-lr-4 .container-lg, .section-padding-lr-4 .container-xl, .section-padding-lr-4 .container-xxl {
  padding-left: 118px;
  padding-right: 118px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-lr-4 .container-fluid, .section-padding-lr-4 .container-sm, .section-padding-lr-4 .container-md, .section-padding-lr-4 .container-lg, .section-padding-lr-4 .container-xl, .section-padding-lr-4 .container-xxl {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-lr-4 .container-fluid, .section-padding-lr-4 .container-sm, .section-padding-lr-4 .container-md, .section-padding-lr-4 .container-lg, .section-padding-lr-4 .container-xl, .section-padding-lr-4 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-lr-4 .container-fluid, .section-padding-lr-4 .container-sm, .section-padding-lr-4 .container-md, .section-padding-lr-4 .container-lg, .section-padding-lr-4 .container-xl, .section-padding-lr-4 .container-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-lr-4 .container-fluid, .section-padding-lr-4 .container-sm, .section-padding-lr-4 .container-md, .section-padding-lr-4 .container-lg, .section-padding-lr-4 .container-xl, .section-padding-lr-4 .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-lr-4 .container-fluid, .section-padding-lr-4 .container-sm, .section-padding-lr-4 .container-md, .section-padding-lr-4 .container-lg, .section-padding-lr-4 .container-xl, .section-padding-lr-4 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-padding-lr-5 .container-fluid, .section-padding-lr-5 .container-sm, .section-padding-lr-5 .container-md, .section-padding-lr-5 .container-lg, .section-padding-lr-5 .container-xl, .section-padding-lr-5 .container-xxl {
  padding-left: 250px;
  padding-right: 250px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-lr-5 .container-fluid, .section-padding-lr-5 .container-sm, .section-padding-lr-5 .container-md, .section-padding-lr-5 .container-lg, .section-padding-lr-5 .container-xl, .section-padding-lr-5 .container-xxl {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-lr-5 .container-fluid, .section-padding-lr-5 .container-sm, .section-padding-lr-5 .container-md, .section-padding-lr-5 .container-lg, .section-padding-lr-5 .container-xl, .section-padding-lr-5 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-lr-5 .container-fluid, .section-padding-lr-5 .container-sm, .section-padding-lr-5 .container-md, .section-padding-lr-5 .container-lg, .section-padding-lr-5 .container-xl, .section-padding-lr-5 .container-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-lr-5 .container-fluid, .section-padding-lr-5 .container-sm, .section-padding-lr-5 .container-md, .section-padding-lr-5 .container-lg, .section-padding-lr-5 .container-xl, .section-padding-lr-5 .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-lr-5 .container-fluid, .section-padding-lr-5 .container-sm, .section-padding-lr-5 .container-md, .section-padding-lr-5 .container-lg, .section-padding-lr-5 .container-xl, .section-padding-lr-5 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-padding-lr-6 .container-fluid, .section-padding-lr-6 .container-sm, .section-padding-lr-6 .container-md, .section-padding-lr-6 .container-lg, .section-padding-lr-6 .container-xl, .section-padding-lr-6 .container-xxl {
  padding-left: 240px;
  padding-right: 240px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-lr-6 .container-fluid, .section-padding-lr-6 .container-sm, .section-padding-lr-6 .container-md, .section-padding-lr-6 .container-lg, .section-padding-lr-6 .container-xl, .section-padding-lr-6 .container-xxl {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-lr-6 .container-fluid, .section-padding-lr-6 .container-sm, .section-padding-lr-6 .container-md, .section-padding-lr-6 .container-lg, .section-padding-lr-6 .container-xl, .section-padding-lr-6 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-lr-6 .container-fluid, .section-padding-lr-6 .container-sm, .section-padding-lr-6 .container-md, .section-padding-lr-6 .container-lg, .section-padding-lr-6 .container-xl, .section-padding-lr-6 .container-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-lr-6 .container-fluid, .section-padding-lr-6 .container-sm, .section-padding-lr-6 .container-md, .section-padding-lr-6 .container-lg, .section-padding-lr-6 .container-xl, .section-padding-lr-6 .container-xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-lr-6 .container-fluid, .section-padding-lr-6 .container-sm, .section-padding-lr-6 .container-md, .section-padding-lr-6 .container-lg, .section-padding-lr-6 .container-xl, .section-padding-lr-6 .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col-34 {
  flex: 0 0 auto;
  width: 34%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-34 {
    width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-34 {
    width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-34 {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-34 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-34 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-34 {
    width: 50%;
  }
}

.col-22-5 {
  flex: 0 0 auto;
  width: 22.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-22-5 {
    width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-22-5 {
    width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-22-5 {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-22-5 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-22-5 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-22-5 {
    width: 50%;
  }
}

.col-24 {
  flex: 0 0 auto;
  width: 24%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-24 {
    width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-24 {
    width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-24 {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-24 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-24 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-24 {
    width: 50%;
  }
}

.col-19-5 {
  flex: 0 0 auto;
  width: 19.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-19-5 {
    width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-19-5 {
    width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-19-5 {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-19-5 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-19-5 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-19-5 {
    width: 50%;
  }
}

.border-top-1 {
  border-top: 1px solid #B1B1B1;
}

.border-bottom-1 {
  border-bottom: 1px solid #A2A2A2;
}

.section-padding-1 {
  padding-top: 130px;
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 {
    padding-top: 88px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 {
    padding-top: 58px;
    padding-bottom: 70px;
  }
}

.section-padding-2 {
  padding-bottom: 161px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 {
    padding-bottom: 83px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 {
    padding-bottom: 53px;
  }
}

.section-padding-3 {
  padding-bottom: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 {
    padding-bottom: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 {
    padding-bottom: 65px;
  }
}

.section-padding-4 {
  padding-top: 145px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

.section-padding-5 {
  padding-top: 245px;
  padding-bottom: 222px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-5 {
    padding-top: 138px;
    padding-bottom: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-5 {
    padding-top: 108px;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 {
    padding-top: 108px;
    padding-bottom: 120px;
  }
}

.section-padding-6 {
  padding-top: 208px;
  padding-bottom: 157px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 {
    padding-top: 100px;
    padding-bottom: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 {
    padding-top: 70px;
    padding-bottom: 65px;
  }
}

.section-padding-7 {
  padding-top: 112px;
  padding-bottom: 165px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 {
    padding-top: 90px;
    padding-bottom: 93px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 {
    padding-top: 60px;
    padding-bottom: 63px;
  }
}

.section-padding-8 {
  padding-bottom: 70px;
}

.section-padding-9 {
  padding-top: 98px;
  padding-bottom: 96px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-9 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-9 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.section-padding-10 {
  padding-top: 132px;
  padding-bottom: 213px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-10 {
    padding-top: 90px;
    padding-bottom: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-10 {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

.section-padding-11 {
  padding-top: 126px;
  padding-bottom: 112px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-11 {
    padding-top: 95px;
    padding-bottom: 92px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-11 {
    padding-top: 66px;
    padding-bottom: 62px;
  }
}

.section-padding-12 {
  padding-top: 122px;
  padding-bottom: 143px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-12 {
    padding-top: 88px;
    padding-bottom: 92px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-12 {
    padding-top: 61px;
    padding-bottom: 62px;
  }
}

.section-padding-13 {
  padding-top: 150px;
  padding-bottom: 143px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-13 {
    padding-top: 90px;
    padding-bottom: 94px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-13 {
    padding-top: 60px;
    padding-bottom: 65px;
  }
}

.section-padding-14 {
  padding-top: 142px;
  padding-bottom: 194px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-14 {
    padding-top: 88px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-14 {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

.section-padding-15 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.custom-d-flex {
  display: flex !important;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

.justify-content-center {
  align-items: center;
}

#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #262626;
  color: #fff;
  right: 50px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 35px;
    height: 35px;
    right: 20px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 35px;
    font-size: 20px;
  }
}

#scrollUp:hover {
  background-color: #BB9B1F;
}

@keyframes lastudioZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*-------- 02. Header style ---------*/
.header-padding-tb {
  padding: 20px 0;
}

@media only screen and (max-width: 767px) {
  .header-padding-tb {
    padding: 12px 0;
  }
}

.header-padding-tb-2 {
  padding: 8px 0;
}

.language-wrap ul {
  display: flex;
}

.language-wrap ul li {
  margin-right: 37px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .language-wrap ul li {
    margin-right: 25px;
  }
}

.language-wrap ul li::before {
  content: '';
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%) rotate(22deg);
  width: 1px;
  height: 18px;
  background: #000;
}

@media only screen and (max-width: 767px) {
  .language-wrap ul li::before {
    height: 12px;
    right: -13px;
  }
}

.language-wrap ul li:last-child {
  margin-right: 0;
}

.language-wrap ul li:last-child::before {
  display: none;
}

.language-wrap ul li a {
  font-size: 24px;
  line-height: 1;
  display: inline-block;
  font-family: "Proxima Nova Rg";
  letter-spacing: 5.22px;
}

@media only screen and (max-width: 767px) {
  .language-wrap ul li a {
    font-size: 17px;
    letter-spacing: 0.22px;
  }
}

.language-wrap ul li a:hover {
  color: #BB9B1F;
}

.language-wrap.language-wrap-white ul li::before {
  background: #ffffff;
}

.language-wrap.language-wrap-white ul li a {
  color: #ffffff;
}

.language-wrap.language-wrap-white ul li a:hover {
  color: #BB9B1F;
}

.logo a {
  display: inline-block;
}

.logo a img {
  max-width: 159px;
  transition: all .4s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo a img {
    max-width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo a img {
    max-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .logo a img {
    max-width: 80px;
  }
}

.header-action-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-action-wrap .header-action-cart {
  margin-right: 68px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action-wrap .header-action-cart {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .header-action-cart {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart {
    margin-right: 20px;
  }
}

.header-action-wrap .header-action-cart > a {
  width: 23px;
  display: inline-block;
  position: relative;
}

.header-action-wrap .header-action-cart > a > .product-count {
  position: absolute;
  top: -21px;
  right: -12px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 100%;
  font-family: "Proxima Nova Rg";
  font-size: 11px;
  display: inline-block;
  text-align: center;
  color: #181818;
  background-color: #ffffff;
}

.header-action-wrap .header-action-cart > a svg {
  width: 24px;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart > a svg {
    width: 22px;
  }
}

.header-action-wrap .header-action-cart > a svg g path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.header-action-wrap .header-action-cart > a:hover svg g path {
  stroke: #BB9B1F;
}

.header-action-wrap .header-action-cart > a img {
  width: 100%;
}

.header-action-wrap .header-action-cart.white-cart > a svg g path {
  stroke: #ffffff;
}

.header-action-wrap .header-action-cart.white-cart > a:hover svg g path {
  stroke: #BB9B1F;
}

.header-action-wrap .header-action-menu {
  width: 32px;
  height: 22px;
}

.header-action-wrap .header-action-menu a {
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 1;
  display: inline-block;
}

.header-action-wrap .header-action-menu a > span {
  background-color: #1E1E1E;
  left: 0;
  display: block;
  position: absolute;
  transition: .4s;
  height: 2px;
}

.header-action-wrap .header-action-menu a > span:nth-child(2) {
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.header-action-wrap .header-action-menu a > span:first-child {
  width: 16px;
  top: 0;
  left: auto;
  right: 0;
}

.header-action-wrap .header-action-menu a > span:last-child {
  width: 16px;
  top: 20px;
}

.header-action-wrap .header-action-menu a:hover span {
  background-color: #BB9B1F;
}

.header-action-wrap .header-action-menu a:hover span:first-child {
  width: 100%;
}

.header-action-wrap .header-action-menu a:hover span:last-child {
  width: 100%;
}

.header-action-wrap .header-action-menu.white-menu > a > span {
  background-color: #ffffff;
}

.header-action-wrap .header-action-menu.white-menu > a:hover span {
  background-color: #BB9B1F;
}

.main-wrapper .body-overlay {
  background-color: #232324;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: .7;
  visibility: visible;
  z-index: 9999;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 37px;
  right: 50px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 27px;
    right: 17px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #BB9B1F;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3, .sidebar-cart-active .sidebar-cart-all .cart-content > .h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 35px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 70px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4, .sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title .h4 {
  font-size: 14px;
  margin: 0 0 8px;
  font-family: "Proxima Nova Rg";
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a:hover, .sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title .h4 a:hover {
  color: #BB9B1F;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 16px;
  color: #8E8E8E;
  font-family: "Proxima Nova Rg";
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-size: 16px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
  color: #000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a:hover {
  color: #BB9B1F;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 27px 0 26px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4, .sidebar-cart-active .sidebar-cart-all .cart-content .cart-total .h4 {
  font-size: 18px;
  text-transform: capitalize;
  margin: 0;
  font-family: "Proxima Nova Rg";
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span, .sidebar-cart-active .sidebar-cart-all .cart-content .cart-total .h4 span {
  font-size: 18px;
  color: #8E8E8E;
  font-family: "Proxima Nova Rg";
  float: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  font-size: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.wrapper-2 .body-overlay-2 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.off-canvas-active {
  position: fixed;
  top: 0;
  width: 370px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  transform: translate(370px, 0);
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .off-canvas-active {
    width: 320px;
    transform: translate(320px, 0);
  }
}

@media only screen and (max-width: 767px) {
  .off-canvas-active {
    width: 260px;
    transform: translate(260px, 0);
  }
}

.off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.off-canvas-active .off-canvas-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  background-color: #343538;
}

.off-canvas-active .off-canvas-close i {
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #fff;
  line-height: 40px;
}

.off-canvas-active .off-canvas-close:hover i {
  color: #BB9B1F;
  transform: rotate(90deg);
}

.off-canvas-active .off-canvas-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .off-canvas-active .off-canvas-wrap {
    padding: 30px 20px 50px;
  }
}

.slinky-theme-default {
  background: transparent;
}

.slinky-theme-default ul li a {
  font-family: "Proxima Nova Rg";
  font-size: 15px;
  position: relative;
}

.slinky-theme-default ul li a:not(.back) {
  padding: 10px 0px;
  color: #181818;
}

.slinky-theme-default ul li a:not(.back):hover {
  color: #BB9B1F;
  background: transparent;
}

.slinky-theme-default .next::after {
  content: "\f105";
  font-family: 'Line Awesome Free';
  font-weight: 900;
  opacity: 1;
  transition: all .3 ease 0s;
  color: #181818;
  font-size: 15px;
  background: none;
}

.slinky-theme-default .next:hover::after {
  color: #BB9B1F;
}

.slinky-theme-default .back::before {
  content: "\f105";
  font-family: 'Line Awesome Free';
  font-weight: 900;
  opacity: 1;
  transition: all .3 ease 0s;
  color: #fff;
  font-size: 15px;
  background: none;
  padding: 0;
  background-color: #000;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0 0 13px 0px;
  border-radius: 3px;
}

.slinky-theme-default .back:hover::before {
  color: #000;
  background-color: #BB9B1F;
}

.sticky-bar.stick {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  transition: all .4s ease 0s;
}

.sticky-bar.stick.sticky-white-bg {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.stick .logo a img {
  max-width: 80px;
}

@media only screen and (max-width: 767px) {
  .stick .logo a img {
    max-width: 70px;
  }
}

.stick.header-padding-tb {
  padding: 10px 0;
}

.header-black-bg {
  background: rgba(24, 24, 24, 0.3) none repeat scroll 0 0;
}

.header-black-bg.stick {
  background: rgba(24, 24, 24, 0.5) none repeat scroll 0 0;
}

.header-height-1 {
  height: 192px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-height-1 {
    height: 164px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-1 {
    height: 135px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-1 {
    height: 100px;
  }
}

.header-height-1.stick {
  height: 96px;
}

@media only screen and (max-width: 767px) {
  .header-height-1.stick {
    height: 86px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.mb-12 {
    margin-bottom: 40px !important;
  }
  .section-title.mb-10 {
    margin-bottom: 42px !important;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.mb-12 {
    margin-bottom: 30px !important;
  }
  .section-title.mb-10 {
    margin-bottom: 32px !important;
  }
  .mb-10 {
    margin-bottom: 2.125rem !important;
  }
}

/*-------- 03. Slider style ---------*/
.slider-height-1 {
  height: 1080px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 774px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 680px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: 550px;
  }
}

.single-slider-wrap {
  position: relative;
}

.single-slider-wrap .slider-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 70.7%;
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-wrap .slider-img {
    width: 82%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-wrap .slider-img {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-wrap .slider-img {
    width: 100%;
  }
}

.single-slider-wrap .slider-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-content {
  position: relative;
  z-index: 9;
  margin: 0 0 0 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content {
    margin: 0 0 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content {
    margin: 0 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content {
    margin: 0 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content {
    margin: 0 15px 0 15px;
    text-align: center;
    width: 100%;
  }
}

.slider-content h3, .slider-content .h3 {
  font-size: 20px;
  color: #4D4D4D;
  font-family: "Proxima Nova Rg";
  letter-spacing: 8.33px;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content h3, .slider-content .h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content h3, .slider-content .h3 {
    font-size: 18px;
    letter-spacing: 6.33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content h3, .slider-content .h3 {
    font-size: 18px;
    letter-spacing: 4.33px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content h3, .slider-content .h3 {
    font-size: 18px;
    letter-spacing: 4.33px;
    color: #ffffff;
  }
}

.slider-content h1, .slider-content .h1 {
  color: #BB9B1F;
  font-size: 138px;
  line-height: .9;
  margin: 24px 0 28px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content h1, .slider-content .h1 {
    font-size: 120px;
    margin: 18px 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content h1, .slider-content .h1 {
    font-size: 100px;
    margin: 15px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content h1, .slider-content .h1 {
    font-size: 90px;
    margin: 15px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content h1, .slider-content .h1 {
    font-size: 70px;
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content h1, .slider-content .h1 {
    font-size: 55px;
    margin: 15px 0 20px;
  }
}

.slider-content.slider-content-red h3, .slider-content.slider-content-red .h3 {
  color: #F46C7D;
}

.slider-content.slider-content-red h1, .slider-content.slider-content-red .h1 {
  font-size: 250px;
  color: #E13636;
  line-height: 235px;
  letter-spacing: -8.93px;
  margin: -33px 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content.slider-content-red h1, .slider-content.slider-content-red .h1 {
    line-height: 180px;
    font-size: 170px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content.slider-content-red h1, .slider-content.slider-content-red .h1 {
    line-height: 145px;
    font-size: 140px;
    margin: -23px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content.slider-content-red h1, .slider-content.slider-content-red .h1 {
    line-height: 145px;
    font-size: 140px;
    margin: -23px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content.slider-content-red h1, .slider-content.slider-content-red .h1 {
    line-height: 125px;
    font-size: 120px;
    margin: -10px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content.slider-content-red h1, .slider-content.slider-content-red .h1 {
    line-height: 90px;
    font-size: 80px;
    margin: 0px 0 0px;
    letter-spacing: -3.93px;
  }
}

.slider-content.slider-content-red.slider-content {
  margin: 151px 0 0 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content.slider-content-red.slider-content {
    margin: 151px 0 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content.slider-content-red.slider-content {
    margin: 135px 0 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content.slider-content-red.slider-content {
    margin: 80px 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content.slider-content-red.slider-content {
    margin: 80px 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content.slider-content-red.slider-content {
    margin: 0 15px 0 15px;
    text-align: center;
    width: 100%;
  }
}

.btn-style a.btn {
  font-family: "Proxima Nova Rg";
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style a.btn {
    padding: 17px 50px 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style a.btn {
    padding: 17px 50px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style a.btn {
    padding: 15px 40px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style a.btn {
    padding: 14px 30px 15px;
    font-size: 18px;
  }
  .btn-style a.btn.slider-btn {
    color: #ffffff;
  }
}

.btn-style a.btn.btn-padding-2 {
  padding: 17px 25px;
}

@media only screen and (max-width: 767px) {
  .btn-style a.btn.btn-padding-2 {
    padding: 10px 25px 11px;
  }
}

.btn-style a.btn-outline-primary {
  border-color: #acacac;
}

.btn-style a.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-style a.btn-outline-primary:active {
  box-shadow: none;
}

.btn-style a.btn-outline-primary:hover {
  border-color: #181818;
}

.single-animation-wrap.slick-active .slider-animated-1 h3, .single-animation-wrap.slick-active .slider-animated-1 .h3 {
  animation-delay: 1.0s;
  animation-name: fadeInLeft;
}

.single-animation-wrap.slick-active .slider-animated-1 h1, .single-animation-wrap.slick-active .slider-animated-1 .h1 {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.single-animation-wrap.slick-active .slider-animated-1 a {
  animation-delay: 1.9s;
  animation-name: fadeInLeft;
}

.single-animation-wrap.slick-active .slider-animated-1.slider-img img {
  animation-delay: 1.0s;
  animation-name: fadeInRight;
}

.nav-style-1 > span {
  position: absolute;
  bottom: 10.5%;
  left: 15.8%;
  font-size: 15px;
  color: #000000;
  width: 47px;
  line-height: 46px;
  height: 47px;
  border-radius: 100%;
  border: 1px solid #979797;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-1 > span {
    left: 3.8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-1 > span {
    left: 1.2%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1 > span {
    left: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1 > span {
    left: 5.2%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span {
    left: 50%;
    transform: translateX(-50%);
  }
}

.nav-style-1 > span svg path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.nav-style-1 > span.slider-icon-next {
  margin-left: 76px;
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span.slider-icon-next {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span.slider-icon-prev {
    margin-left: -30px;
  }
}

.nav-style-1 > span:hover {
  background-color: #BB9B1F;
  border: 1px solid #BB9B1F;
}

.nav-style-1 > span:hover svg path {
  stroke: #fff;
}

.nav-style-1.nav-style-1-modify > span {
  color: #1E1E1E;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.nav-style-1.nav-style-1-modify > span:hover {
  background-color: #E13636;
  border: 1px solid #E13636;
}

/*-------- 04. Features style ---------*/
.features-wrap .features-img {
  width: 45px;
  display: inline-block;
  transition: all 0.5s ease 0s;
}

.features-wrap .features-img svg g path {
  stroke: #1E1E1E;
}

.features-wrap .features-img img {
  width: 100%;
}

.features-wrap h3, .features-wrap .h3 {
  font-size: 24px;
  margin: 29px 0 5px;
}

@media only screen and (max-width: 767px) {
  .features-wrap h3, .features-wrap .h3 {
    margin: 20px 0 5px;
  }
}

.features-wrap p {
  font-size: 18px;
  line-height: 32px;
  margin: 0 auto;
  width: 73%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-wrap p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-wrap p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .features-wrap p {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .features-wrap p {
    width: 63%;
  }
}

.features-wrap:hover .features-img {
  animation: 0.5s ease-in-out 0s normal none 1 running tada;
}

.features-wrap:hover .features-img svg g path {
  stroke: #BB9B1F;
}

.features-all-wrap {
  margin: 0 -65px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .features-all-wrap {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .features-all-wrap {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-all-wrap {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-all-wrap {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .features-all-wrap {
    margin: 0 0px;
  }
}

/*-------- 05. Section title style ---------*/
.section-title h2, .section-title .h2 {
  font-size: 36px;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2, .section-title .h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2, .section-title .h2 {
    font-size: 28px;
  }
}

.section-title h2.white, .section-title .white.h2 {
  color: #ffffff;
}

.section-title p {
  color: #ffffff;
  width: 54%;
  margin: 11px auto 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title p {
    width: 68%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title p {
    width: 91%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title p {
    width: 100%;
  }
}

.section-title-2 h2, .section-title-2 .h2 {
  font-size: 56px;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 h2, .section-title-2 .h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2, .section-title-2 .h2 {
    font-size: 30px;
  }
}

.section-title-3 h2, .section-title-3 .h2 {
  font-size: 46px;
  line-height: 1;
  letter-spacing: -1.64px;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3 h2, .section-title-3 .h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2, .section-title-3 .h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-3.mb-100 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3.mb-100 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3.mb-100 {
    margin-bottom: 30px;
  }
}

.section-title-4 h2, .section-title-4 .h2 {
  font-size: 32px;
  line-height: 1;
  letter-spacing: -1.64px;
  margin: 0;
  display: inline-block;
  transform: translateY(-18px);
  background-color: #ffffff;
  padding: 0 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4.mb-100 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-100 {
    margin-bottom: 20px;
  }
}

/*-------- 24. Login register style ---------*/
.trend-product-wrap {
  position: relative;
}

.trend-product-wrap .product-img-position {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50.5%;
  left: 0;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-wrap .product-img-position {
    width: 100%;
    position: static;
    transform: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-wrap .product-img-position {
    width: 100%;
    position: static;
    transform: inherit;
  }
}

.trend-product-wrap .product-img-position img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.trend-product-wrap .product-img-position-2 {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50.5%;
  right: 0;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-wrap .product-img-position-2 {
    width: 100%;
    position: static;
    transform: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-wrap .product-img-position-2 {
    width: 100%;
    position: static;
    transform: inherit;
  }
}

.trend-product-wrap .product-img-position-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.trend-product-content {
  padding: 200px 260px 175px 124px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trend-product-content {
    padding: 100px 50px 75px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content {
    padding: 63px 50px 75px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content {
    padding: 63px 30px 75px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content {
    padding: 40px 40px 0px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content {
    padding: 40px 15px 0px 15px;
    text-align: center;
  }
}

.trend-product-content.trend-product-content-modify {
  padding: 200px 150px 175px 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trend-product-content.trend-product-content-modify {
    padding: 100px 50px 75px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content.trend-product-content-modify {
    padding: 63px 50px 75px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content.trend-product-content-modify {
    padding: 63px 30px 75px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content.trend-product-content-modify {
    padding: 40px 40px 0px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content.trend-product-content-modify {
    padding: 40px 15px 0px 15px;
    text-align: center;
  }
}

.trend-product-content.trend-product-content-modify p {
  width: 86%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content.trend-product-content-modify p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content.trend-product-content-modify p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content.trend-product-content-modify p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content.trend-product-content-modify p {
    width: 100%;
  }
}

.trend-product-content.trend-product-content-modify-2 {
  padding: 200px 100px 175px 124px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trend-product-content.trend-product-content-modify-2 {
    padding: 100px 50px 75px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content.trend-product-content-modify-2 {
    padding: 63px 50px 75px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content.trend-product-content-modify-2 {
    padding: 63px 30px 75px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content.trend-product-content-modify-2 {
    padding: 40px 40px 0px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content.trend-product-content-modify-2 {
    padding: 40px 15px 0px 15px;
    text-align: center;
  }
}

.trend-product-content.trend-product-content-modify-2 p {
  width: 80%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trend-product-content.trend-product-content-modify-2 p {
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content.trend-product-content-modify-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content.trend-product-content-modify-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content.trend-product-content-modify-2 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content.trend-product-content-modify-2 p {
    width: 100%;
  }
}

.trend-product-content h2, .trend-product-content .h2 {
  font-size: 100px;
  margin: 0;
  line-height: .8;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trend-product-content h2, .trend-product-content .h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content h2, .trend-product-content .h2 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content h2, .trend-product-content .h2 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content h2, .trend-product-content .h2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content h2, .trend-product-content .h2 {
    font-size: 45px;
  }
}

.trend-product-content p {
  font-size: 20px;
  line-height: 32px;
  margin: 45px 0 44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-content p {
    margin: 30px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-content p {
    margin: 25px 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-content p {
    margin: 25px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-content p {
    margin: 15px 0 25px;
    font-size: 18px;
    line-height: 30px;
  }
}

.tab-style-1 {
  justify-content: center;
}

.tab-style-1 a {
  font-size: 24px;
  color: #ACACAC;
  letter-spacing: 1.5px;
  display: inline-block;
  margin: 0 47px;
  line-height: .8;
  font-family: "Proxima Nova Rg";
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-style-1 a {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-1 a {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 10px 15px;
    font-size: 20px;
  }
}

.tab-style-1 a.active {
  color: #181818;
  border-bottom: 1px solid #181818;
}

.tab-style-1 a:hover {
  color: #BB9B1F;
}

@media only screen and (max-width: 767px) {
  .tab-style-1.mb-10 {
    margin-bottom: 24px !important;
  }
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: lastudioZoomIn;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.product-wrap .product-img {
  position: relative;
}

.product-wrap .product-img a {
  display: block;
}

.product-wrap .product-img a img {
  width: 100%;
}

.product-wrap .product-img .product-badge {
  position: absolute;
  right: 0;
  top: 0;
}

.product-wrap .product-img .product-badge span {
  color: #fff;
  background-color: #181818;
  line-height: 1;
  display: inline-block;
  font-family: "Proxima Nova Rg";
  padding: 5px 18px 7px;
}

.product-wrap .product-img .product-action-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  padding: 17px 10px 5px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .4s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .product-action-wrap.product-action-padding {
  padding: 17px 10px 17px;
}

.product-wrap .product-img .product-action-wrap button {
  border: none;
  padding: 0;
  margin: 0 11px;
  line-height: 1;
  background-color: transparent;
}

.product-wrap .product-img .product-action-wrap button svg g path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.product-wrap .product-img .product-action-wrap button svg path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.product-wrap .product-img .product-action-wrap button:hover svg g path {
  stroke: #BB9B1F;
}

.product-wrap .product-img .product-action-wrap button:hover svg path {
  stroke: #BB9B1F;
}

.product-wrap .product-content h3, .product-wrap .product-content .h3 {
  font-size: 14px;
  margin: 0 0 14px;
  font-family: "Proxima Nova Rg";
}

.product-wrap .product-content .product-price span {
  color: #8E8E8E;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  display: inline-block;
  line-height: 1;
}

.product-wrap .product-content .product-price span.old-price {
  text-decoration: line-through;
  margin-left: 5px;
}

.product-wrap .product-content-2 {
  display: flex;
  justify-content: space-between;
}

.product-wrap .product-content-2 h3, .product-wrap .product-content-2 .h3 {
  font-size: 20px;
  font-family: "Proxima Nova Rg";
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2 h3, .product-wrap .product-content-2 .h3 {
    font-size: 18px;
  }
}

.product-wrap .product-content-2 h3 a, .product-wrap .product-content-2 .h3 a {
  color: #4D4D4D;
}

.product-wrap .product-content-2 h3 a:hover, .product-wrap .product-content-2 .h3 a:hover {
  color: #BB9B1F;
}

.product-wrap .product-content-2 span {
  font-size: 20px;
  font-family: "Proxima Nova Rg";
  line-height: 1;
  display: inline-block;
  color: #BB9B1F;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2 span {
    font-size: 18px;
  }
}

.product-wrap:hover .img-zoom a img {
  transform: scale(1.1);
}

.product-wrap:hover .product-action-wrap {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.img-zoom {
  overflow: hidden;
}

.img-zoom a img {
  transition: all .5s ease 0s;
  transform: scale(1);
}

.padding-54-row-col .row {
  margin-right: -27px;
  margin-left: -27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-54-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-54-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-54-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-54-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-54-row-col .row div[class^="col-"] {
  padding-left: 27px;
  padding-right: 27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-54-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-54-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-54-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-54-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product-slider-active .slick-list {
  margin: 0 -27px;
  padding: 0 195px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-slider-active .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active .slick-list {
    margin: 0 -15px;
    padding: 0 95px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active .slick-list {
    margin: 0 -15px;
    padding: 0 95px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active .slick-list {
    margin: 0 -15px;
    padding: 0 60px 0 0;
  }
}

.product-slider-active .slick-list .product-plr-1 {
  padding: 0 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

.product-slider-active-2 .slick-list {
  margin: 0 -27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-slider-active-2 .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active-2 .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-2 .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-2 .slick-list {
    margin: 0 -15px;
  }
}

.product-slider-active-2 .slick-list .product-plr-1 {
  padding: 0 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active-2 .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-2 .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-2 .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

.product-area-wrap {
  padding: 0 0 0 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-area-wrap {
    padding: 0 0 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-area-wrap {
    padding: 0 0 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-area-wrap {
    padding: 0 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area-wrap {
    padding: 0 0 0 120px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area-wrap {
    padding: 0 0 0 80px;
  }
}

.nav-style-2 > span {
  position: absolute;
  top: 34.7%;
  left: -80px;
  z-index: 9;
  display: block;
  cursor: pointer;
  width: 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-2 > span {
    top: 28%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-2 > span {
    top: 24%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-2 > span {
    top: 24.7%;
    left: -45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-2 > span {
    top: 24.7%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span {
    left: -65px;
    top: 22%;
  }
}

.nav-style-2 > span.product-icon-next {
  margin-top: 70px;
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span.product-icon-next {
    margin-top: 60px;
  }
}

.nav-style-2 > span svg {
  width: 25px;
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span svg {
    width: 18px;
  }
}

.nav-style-2 > span svg path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.nav-style-2 > span img {
  width: 100%;
}

.nav-style-2 > span:hover svg path {
  stroke: #BB9B1F;
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.collection-wrap {
  overflow: hidden;
}

.collection-wrap a {
  display: block;
}

.collection-wrap a img {
  width: 100%;
  transition: all .5s ease 0s;
}

.collection-wrap:hover a img {
  transform: scale(1.1);
}

.view-all a {
  display: inline-block;
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .view-all.mt-95 {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .view-all.mt-95 {
    margin-top: 25px;
  }
}

.shop-top-bar {
  display: flex;
  justify-content: space-between;
  margin: 0 0 57px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-top-bar {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    margin: 0 0 30px;
  }
  .shop-top-bar.shop-top-bar-responsive {
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar.shop-top-bar-responsive {
    display: flex;
    text-align: inherit;
  }
}

.shop-top-bar .show-wrap span {
  color: #6D6D6D;
  font-size: 14px;
  display: inline-block;
  font-family: "Proxima Nova Rg";
}

.nice-select-style-2 {
  border: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  font-family: "Proxima Nova Rg";
  font-size: 13px;
  color: #6D6D6D;
  text-transform: uppercase;
  padding: 0 22px 0 0;
  height: auto;
  line-height: 27px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-down.png") no-repeat scroll right 0px center;
}

.nice-select-style-2.open {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-up-2.png") no-repeat scroll right 0px center;
}

.nice-select-style-2.nice-select::after {
  display: none;
}

.nice-select-style-2.nice-select .list {
  left: auto;
  right: 0;
}

.nice-select-style-2 ul {
  z-index: 9 !important;
  min-width: 200px;
}

.nice-select-style-2 ul li {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 20px;
}

.sorting-filter-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .sorting-filter-wrap {
    margin: 8px 0 0;
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sorting-filter-wrap {
    margin: 0px 0 0;
    justify-content: inherit;
  }
}

.sorting-filter-wrap .filter-wrap {
  margin-left: 30px;
}

.sorting-filter-wrap .filter-wrap a {
  color: #6D6D6D;
  font-size: 13px;
  font-family: "Proxima Nova Rg";
  text-transform: uppercase;
}

.sorting-filter-wrap .filter-wrap a:hover, .sorting-filter-wrap .filter-wrap a.active {
  color: #BB9B1F;
}

.sorting-filter-wrap .filter-wrap a img {
  margin-left: 5px;
  margin-top: -5px;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 45px 40px 18px;
  display: none;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 45px 25px 18px;
  }
}

.pagination-style ul {
  display: flex;
  justify-content: center;
}

.pagination-style ul li {
  margin: 0 20px 0 0;
}

.pagination-style ul li:last-child {
  margin: 0 0 0 0;
}

@media only screen and (max-width: 767px) {
  .pagination-style ul li {
    margin: 0 14px 0;
  }
}

.pagination-style ul li a {
  font-size: 12px;
  color: #1D1D1D;
  text-align: center;
  display: inline-block;
  font-family: "Proxima Nova Rg";
  border: 1px solid #CDCDCD;
  width: 37px;
  height: 37px;
  line-height: 37px;
  border-radius: 100%;
}

.pagination-style ul li a.active, .pagination-style ul li a:hover {
  background-color: #1D1D1D;
  color: #ffffff;
  border: 1px solid #1D1D1D;
}

.pagination-style.pagination-left ul {
  justify-content: flex-start;
}

@media only screen and (max-width: 767px) {
  .pagination-style.mt-30 {
    margin-top: 0px;
  }
}

.trend-product-padding {
  padding: 160px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trend-product-padding {
    padding: 120px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trend-product-padding {
    padding: 120px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trend-product-padding {
    padding: 120px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trend-product-padding {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .trend-product-padding {
    padding: 70px 0;
  }
}

.related-product-active .slick-list {
  margin: 0 -27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .related-product-active .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .related-product-active .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related-product-active .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .related-product-active .slick-list {
    margin: 0 -15px;
  }
}

.related-product-active .slick-list .product-plr-1 {
  padding: 0 27px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .related-product-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .related-product-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related-product-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .related-product-active .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-page-wrap .mb-75 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-page-wrap .mb-75 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-page-wrap .mb-75 {
    margin-bottom: 30px;
  }
}

/*-------- 24. Login register style ---------*/
.subscribe-form form .mc-form {
  margin: 0 200px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form form .mc-form {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form form .mc-form {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form {
    margin: 0 0px;
  }
}

.subscribe-form form .mc-form input {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 30px;
  padding: 0px 100px 0 0;
  border-bottom: 1px solid #ffffff;
}

.subscribe-form form .mc-form input::-moz-input-placeholder {
  opacity: 1;
  color: #ffffff;
}

.subscribe-form form .mc-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #ffffff;
}

.subscribe-form form .mc-form .mc-news {
  display: none;
}

.subscribe-form form .mc-form .clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form form .mc-form .clear input {
  width: auto;
  border-bottom: none;
  padding: 0;
}

/*-------- 24. Login register style ---------*/
.banner-wrap {
  position: relative;
  overflow: hidden;
}

.banner-wrap > a {
  display: block;
}

.banner-wrap > a img {
  width: 100%;
  transition: all .5s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.blog-banner-img > a img {
    max-width: 100%;
    width: inherit;
  }
}

.banner-wrap .banner-content-1 {
  position: absolute;
  bottom: 8px;
  left: 0;
}

.banner-wrap .banner-content-1 h4, .banner-wrap .banner-content-1 .h4 {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 4px;
  display: inline-block;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  padding: 26px 273px 25px 40px;
  margin: 0;
  background: linear-gradient(270deg, rgba(187, 155, 31, 0), #BB9B1F);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h4, .banner-wrap .banner-content-1 .h4 {
    padding: 26px 173px 25px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h4, .banner-wrap .banner-content-1 .h4 {
    padding: 26px 70px 25px 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-1 h4, .banner-wrap .banner-content-1 .h4 {
    padding: 26px 120px 25px 40px;
  }
}

.banner-wrap .banner-content-1 h2, .banner-wrap .banner-content-1 .h2 {
  font-size: 106px;
  color: #ffffff;
  line-height: 1;
  margin: -19px 0 0 38px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h2, .banner-wrap .banner-content-1 .h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h2, .banner-wrap .banner-content-1 .h2 {
    font-size: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h2, .banner-wrap .banner-content-1 .h2 {
    font-size: 60px;
  }
}

.banner-wrap .banner-content-2 {
  position: absolute;
  left: 72px;
  bottom: 68px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 {
    left: 30px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 {
    left: 30px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    left: 20px;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.banner-wrap .banner-content-2 h3, .banner-wrap .banner-content-2 .h3 {
  font-size: 28px;
  margin: 0;
  font-family: "Proxima Nova Rg";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h3, .banner-wrap .banner-content-2 .h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3, .banner-wrap .banner-content-2 .h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-2 h2, .banner-wrap .banner-content-2 .h2 {
  font-size: 66px;
  line-height: 1;
  margin: 4px 0 29px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h2, .banner-wrap .banner-content-2 .h2 {
    font-size: 50px;
    margin: 4px 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2, .banner-wrap .banner-content-2 .h2 {
    font-size: 38px;
    margin: 4px 0 15px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 52px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 {
    bottom: 30px;
  }
}

.banner-wrap .banner-content-3 h4, .banner-wrap .banner-content-3 .h4 {
  font-size: 22px;
  letter-spacing: 2px;
  font-family: "Proxima Nova Lt";
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h4, .banner-wrap .banner-content-3 .h4 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-3 h2, .banner-wrap .banner-content-3 .h2 {
  color: #F46C7D;
  font-size: 83px;
  margin: 0px 0 18px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h2, .banner-wrap .banner-content-3 .h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h2, .banner-wrap .banner-content-3 .h2 {
    font-size: 75px;
  }
}

.banner-wrap .banner-content-4 {
  position: absolute;
  left: 30px;
  bottom: 52px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 {
    left: 10px;
    bottom: 30px;
  }
}

.banner-wrap .banner-content-4 h2, .banner-wrap .banner-content-4 .h2 {
  font-size: 32px;
  color: #ffffff;
  margin: 0 0 3px;
}

.banner-wrap .banner-content-4 h3, .banner-wrap .banner-content-4 .h3 {
  margin: 0;
  font-size: 16px;
  color: #ffffff;
}

.banner-wrap:hover a img {
  transform: scale(1.1);
}

.banner-btn-style a.btn {
  display: inline-block;
  line-height: 1;
  color: #ffffff;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  background-color: #D95252;
  padding: 10px 44px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-btn-style a.btn {
    padding: 10px 30px;
  }
}

/*-------- 24. Login register style ---------*/
.brand-logo-active .slick-list {
  margin: 0 -15px;
}

.brand-logo-active .slick-list .brand-logo-plr-1 {
  padding: 0 15px;
}

.brand-logo-active {
  margin: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .brand-logo-active {
    margin: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .brand-logo-active {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-logo-active {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-active {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-active {
    margin: 0 0px;
  }
}

.single-brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
}

.single-brand-logo img {
  max-width: 100%;
  filter: grayscale(100%);
  transition: all .3s ease 0s;
}

.single-brand-logo img:hover {
  filter: grayscale(0%);
}

/*-------- 24. Login register style ---------*/
.footer-logo a {
  display: block;
}

.footer-logo a img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .footer-logo a img {
    max-width: 120px;
  }
}

.footer-list ul li {
  display: block;
  margin: 0 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-list ul li {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-list ul li {
    margin: 0 0 10px;
  }
}

.footer-list ul li:last-child {
  margin: 0 0 0;
}

.footer-list ul li a {
  font-size: 20px;
  display: block;
  font-family: "Proxima Nova Lt";
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-list ul li a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-list ul li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-list ul li a {
    font-size: 16px;
  }
}

.copyright {
  padding: 23px 0 26px;
}

.copyright p {
  margin: 0;
  color: #282828;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Proxima Nova Rg";
}

.copyright p svg {
  margin: 0 3px;
}

.copyright p a {
  color: #BB9B1F;
  display: inline-block;
  margin: 0 0 0 4px;
}

.social-icon {
  display: flex;
  flex-wrap: wrap;
  margin: 31px 0 0;
}

.social-icon a {
  margin-right: 35px;
}

.social-icon a:last-child {
  margin-right: 0;
}

.social-icon a svg path {
  fill: #1E1E1E;
  transition: all .3s ease 0s;
}

.social-icon a svg g path {
  fill: #1E1E1E;
  transition: all .3s ease 0s;
}

.social-icon a:hover svg path {
  fill: #BB9B1F;
}

.social-icon a:hover svg g path {
  fill: #BB9B1F;
}

/*-------- 24. Login register style ---------*/
.instagram-paragraph p {
  color: #6D6D6D;
  font-size: 18px;
  line-height: 36px;
  font-family: "Proxima Nova Rg";
  margin: 0 auto 95px;
  width: 51%;
  text-align: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-paragraph p {
    width: 57%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-paragraph p {
    width: 57%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-paragraph p {
    width: 68%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-paragraph p {
    width: 85%;
    margin: 0 auto 60px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-paragraph p {
    width: 100%;
    margin: 0 auto 50px;
  }
}

.instagram-img-wrap {
  margin: 0 6px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-img-wrap {
    margin: 0 0px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-img-wrap {
    margin: 0 0px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-img-wrap {
    margin: 0 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-img-wrap {
    margin: 0 0px 0 0;
  }
}

.instagram-img {
  overflow: hidden;
}

.instagram-img a {
  display: block;
}

.instagram-img a img {
  width: 100%;
  transition: all .5s ease 0s;
}

.instagram-img:hover a img {
  transform: scale(1.1);
}

.instagram-title {
  margin: 0 0 0 145px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-title {
    margin: 0 0 0 115px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-title {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-title {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-title {
    margin: 30px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-title {
    margin: 30px 0 0 0px;
  }
}

.instagram-title h2, .instagram-title .h2 {
  font-size: 36px;
  margin: 0 0 50px;
  line-height: 1;
  padding: 58px 0 60px;
  border-bottom: 1px solid #7D7D7D;
  border-top: 1px solid #7D7D7D;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-title h2, .instagram-title .h2 {
    padding: 28px 0 30px;
    margin: 0 0 30px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-title h2, .instagram-title .h2 {
    padding: 28px 0 30px;
    margin: 0 0 30px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-title h2, .instagram-title .h2 {
    padding: 20px 0 22px;
    margin: 0 0 25px;
  }
}

.instagram-title p {
  font-size: 20px;
  color: #4D4D4D;
  margin: 0 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-title p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-title p {
    font-size: 18px;
  }
}

.instagram-title-2 {
  margin: 0 57px 75px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-title-2 {
    margin: 0 0px 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-title-2 {
    margin: 0 0px 75px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-title-2 {
    margin: 0 0px 30px;
  }
}

.instagram-title-2 h2, .instagram-title-2 .h2 {
  font-size: 36px;
  padding: 0 0 19px;
  margin: 0 0 17px;
  border-bottom: 1px solid #7D7D7D;
}

@media only screen and (max-width: 767px) {
  .instagram-title-2 h2, .instagram-title-2 .h2 {
    font-size: 32px;
    padding: 0 0 14px;
    margin: 0 0 12px;
  }
}

.instagram-title-2 p {
  font-size: 20px;
  line-height: 32px;
  color: #4D4D4D;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .instagram-title-2 p {
    font-size: 18px;
    line-height: 30px;
  }
}

.instagram-slider-active .slick-list {
  margin: 0 -15px;
}

.instagram-slider-active .slick-list .instagram-plr-1 {
  padding: 0 15px;
}

.padding-46-row-col .row {
  margin-right: -23px;
  margin-left: -23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-46-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-46-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-46-row-col .row div[class^="col-"] {
  padding-left: 23px;
  padding-right: 23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-46-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-46-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.view-more a {
  color: #1D1D1D;
  font-size: 16px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .view-more.mt-25 {
    margin-top: 0px;
  }
}

.toggle-item-active {
  display: none;
}

/*-------- 24. Login register style ---------*/
.breadcrumb-padding {
  padding: 295px 0 144px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding {
    padding: 250px 0 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding {
    padding: 250px 0 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding {
    padding: 174px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding {
    padding: 137px 0 50px;
  }
}

.breadcrumb-padding-2 {
  padding: 295px 0 95px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding-2 {
    padding: 250px 0 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-2 {
    padding: 250px 0 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-2 {
    padding: 174px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-2 {
    padding: 137px 0 50px;
  }
}

.breadcrumb-padding-3 {
  padding: 295px 0 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding-3 {
    padding: 250px 0 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-3 {
    padding: 250px 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-3 {
    padding: 174px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-3 {
    padding: 137px 0 50px;
  }
}

.breadcrumb-padding-4 {
  padding: 295px 0 135px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-padding-4 {
    padding: 230px 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding-4 {
    padding: 230px 0 65px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-4 {
    padding: 230px 0 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-4 {
    padding: 174px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-4 {
    padding: 137px 0 50px;
  }
}

.breadcrumb-padding-5 {
  padding: 295px 0 158px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-padding-5 {
    padding: 230px 0 71px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding-5 {
    padding: 230px 0 71px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-5 {
    padding: 218px 0 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-5 {
    padding: 174px 0 33px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-5 {
    padding: 137px 0 48px;
  }
}

.breadcrumb-padding-6 {
  padding: 295px 0 128px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding-6 {
    padding: 220px 0 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-6 {
    padding: 220px 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-6 {
    padding: 170px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-6 {
    padding: 137px 0 50px;
  }
}

.breadcrumb-padding-7 {
  padding: 295px 0 63px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-padding-7 {
    padding: 220px 0 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-7 {
    padding: 220px 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-7 {
    padding: 170px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-7 {
    padding: 137px 0 50px;
  }
}

.breadcrumb-content .breadcrumb-title h2, .breadcrumb-content .breadcrumb-title .h2 {
  font-size: 56px;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content .breadcrumb-title h2, .breadcrumb-content .breadcrumb-title .h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .breadcrumb-title h2, .breadcrumb-content .breadcrumb-title .h2 {
    font-size: 50px;
  }
  .breadcrumb-content .breadcrumb-title h2.breadcrumb-title-responsive, .breadcrumb-content .breadcrumb-title .breadcrumb-title-responsive.h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2, .breadcrumb-content .breadcrumb-title .h2 {
    font-size: 32px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .breadcrumb-title h2, .breadcrumb-content .breadcrumb-title .h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2, .breadcrumb-content .breadcrumb-title .h2 {
    font-size: 22px;
  }
}

.breadcrumb-content ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.breadcrumb-content ul li {
  color: #8E8E8E;
  display: inline-block;
  font-family: "Proxima Nova Rg";
  margin-right: 20px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content ul li {
    margin-right: 14px;
  }
}

.breadcrumb-content ul li:last-child {
  margin-right: 0;
}

.breadcrumb-content ul li a {
  color: #8E8E8E;
  display: inline-block;
  font-family: "Proxima Nova Rg";
  line-height: 1;
}

.breadcrumb-content ul li a:hover {
  color: #BB9B1F;
}

/*-------- 24. Login register style ---------*/
.sidebar-widget .sidebar-widget-title {
  color: #BB9B1F;
  letter-spacing: 1px;
  font-size: 22px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-title {
    font-size: 18px;
  }
}

.sidebar-widget .sidebar-widget-title2 {
  font-size: 22px;
  color: #1D1D1D;
  letter-spacing: 2.15px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .shop-category {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .shop-category {
    margin-top: 25px;
  }
}

.sidebar-widget .shop-category ul li {
  display: block;
  margin: 0 0 15px;
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 14px;
}

.sidebar-widget .shop-category ul li:last-child {
  margin: 0 0 0;
  border-bottom: none;
  padding-bottom: 0;
}

.sidebar-widget .shop-category ul li a {
  font-size: 18px;
  color: #6D6D6D;
  display: block;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .shop-category ul li a:hover {
  color: #BB9B1F;
}

.sidebar-widget .price-filter {
  margin-top: 44px;
}

.sidebar-widget .price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

.sidebar-widget .price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #181818 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 20px;
  margin-left: 0;
  width: 20px;
  margin-top: -3px;
  border: 1px solid #979797;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 34px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  display: flex;
}

.sidebar-widget .price-filter .price-slider-amount .label-input > span {
  color: #1D1D1D;
  font-size: 12px;
  display: inline-block;
  margin-right: 3px;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  color: #1D1D1D;
  height: auto;
  width: 65px;
  font-size: 12px;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .price-filter .price-slider-amount .label-input input::-moz-input-placeholder {
  color: #1D1D1D;
  opacity: 1;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input::-webkit-input-placeholder {
  color: #1D1D1D;
  opacity: 1;
}

.sidebar-widget .price-filter .price-slider-amount button {
  color: #1D1D1D;
  line-height: 1;
  padding: 13px 36px 12px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
  border: 2px solid #E3E3E3;
  background-color: transparent;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .price-filter .price-slider-amount button {
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount button {
    padding: 10px 15px;
  }
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #BB9B1F;
  border: 2px solid #BB9B1F;
  color: #ffffff;
}

.sidebar-widget .sidebar-widget-list {
  margin-top: 43px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-list {
    margin-top: 28px;
  }
}

.sidebar-widget .sidebar-widget-list ul li {
  align-items: center;
  position: relative;
  padding: 0px 0 15px;
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding: 0px 0 0px;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left {
  position: relative;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark {
  background-color: #BB9B1F;
  border: 1px solid #BB9B1F;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark::after {
  display: block;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 0px;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sidebar-widget .sidebar-widget-list ul li a {
  margin-left: 37px;
  display: inline-block;
  line-height: 1;
  color: #6D6D6D;
  font-size: 12px;
  font-family: "Proxima Nova Rg";
  text-transform: uppercase;
}

.sidebar-widget .sidebar-widget-list ul li a:hover {
  color: #181818;
  border-bottom: 1px solid #181818;
}

.sidebar-widget .sidebar-widget-size {
  margin-top: 37px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-size {
    margin-top: 30px;
  }
}

.sidebar-widget .sidebar-widget-size ul {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-widget .sidebar-widget-size ul li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.sidebar-widget .sidebar-widget-size ul li:last-child {
  margin-right: 0;
}

.sidebar-widget .sidebar-widget-size ul li a {
  width: 55px;
  height: 45px;
  font-size: 12px;
  font-family: "Proxima Nova Rg";
  color: #181818;
  border: 1px solid #E6E6E6;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  line-height: 45px;
}

.sidebar-widget .sidebar-widget-size ul li a:hover {
  color: #ffffff;
  background-color: #BB9B1F;
  border: 1px solid #BB9B1F;
}

.sidebar-widget .sidebar-widget-color {
  margin-top: 22px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-color {
    margin-top: 15px;
  }
}

.sidebar-widget .sidebar-widget-color ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sidebar-widget .sidebar-widget-color ul li {
  margin-right: 16px;
  margin-bottom: 5px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 12px;
  }
}

.sidebar-widget .sidebar-widget-color ul li:last-child {
  margin-right: 0;
}

.sidebar-widget .sidebar-widget-color ul li a {
  width: 25px;
  height: 25px;
  font-size: 0;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.sidebar-widget .sidebar-widget-color ul li a.white {
  background-color: #ffffff;
  border: 1px solid #979797;
}

.sidebar-widget .sidebar-widget-color ul li a.pink {
  background-color: #F46C7D;
}

.sidebar-widget .sidebar-widget-color ul li a.yellow {
  background-color: #BB9B1F;
}

.sidebar-widget .sidebar-widget-color ul li a.black {
  background-color: #4D4D4D;
}

.sidebar-widget .sidebar-widget-color ul li a.blue {
  background-color: #6CB8F4;
}

.sidebar-widget .sidebar-widget-tag {
  margin-top: 38px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-tag {
    margin-top: 28px;
  }
}

.sidebar-widget .sidebar-widget-tag ul {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-widget .sidebar-widget-tag ul li {
  margin: 0 10px 10px 0px;
}

.sidebar-widget .sidebar-widget-tag ul li a {
  display: inline-block;
  border: 1px solid #E6E6E6;
  color: #181818;
  line-height: 1;
  padding: 15px 15px;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .sidebar-widget-tag ul li a:hover {
  color: #ffffff;
  border: 1px solid #BB9B1F;
  background-color: #BB9B1F;
}

.sidebar-widget .sidebar-search .sidebar-search-form {
  position: relative;
}

.sidebar-widget .sidebar-search .sidebar-search-form input {
  border: 1px solid #CCCCCC;
  width: 100%;
  height: 60px;
  color: #B5B5B5;
  padding: 2px 50px 2px 20px;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .sidebar-search .sidebar-search-form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  padding: 0;
  background-color: transparent;
  transition: all .3s ease 0s;
}

.sidebar-widget .sidebar-search .sidebar-search-form button svg g path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.sidebar-widget .sidebar-search .sidebar-search-form button:hover svg g path {
  stroke: #BB9B1F;
}

.sidebar-widget .blog-category {
  margin: 47px 0 0;
}

.sidebar-widget .blog-category ul li {
  margin: 0 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-widget .blog-category ul li:last-child {
  margin: 0 0 0;
}

.sidebar-widget .blog-category ul li a {
  display: inline-block;
  line-height: 1;
  font-family: "Proxima Nova Rg";
  color: #181818;
}

.sidebar-widget .blog-category ul li a:hover {
  text-decoration: underline;
}

.sidebar-widget .blog-category ul li span {
  color: #6D6D6D;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .sidebar-blog-wrap {
  margin: 28px 0 0;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog {
  display: flex;
  border-bottom: 1px solid #DCDCDC;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-img {
  flex: 0 0 100px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-img {
    flex: 0 0 80px;
  }
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-img a {
  display: inline-block;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-img a img {
  width: 100%;
  transition: all .4s ease 0s;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content {
  margin: 12px 0 0 21px;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content h3, .sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content .h3 {
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  margin: 0 0 6px;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content h3 a, .sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content .h3 a {
  color: #2F2F2F;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content h3 a:hover, .sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content .h3 a:hover {
  color: #BB9B1F;
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog .sidebar-blog-content span {
  color: #6D6D6D;
  font-size: 10px;
  display: inline-block;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .sidebar-blog-wrap .single-sidebar-blog:hover .sidebar-blog-img a img {
  transform: scale(1.1);
}

.sidebar-widget .sidebar-archive-wrap {
  margin: 30px 0 0;
}

.sidebar-widget .sidebar-archive-wrap select {
  color: #3E3E3E;
  font-family: "Proxima Nova Rg";
  border: 1px solid #D4D4D4;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 2px 50px 0 19px;
  height: 50px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-down-2.svg") no-repeat scroll right 20px center;
}

.sidebar-widget .sidebar-widget-tag-2 {
  margin: 36px 0 0;
}

.sidebar-widget .sidebar-widget-tag-2 ul li {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 9px;
}

.sidebar-widget .sidebar-widget-tag-2 ul li a {
  color: #535353;
  font-family: "Proxima Nova Rg";
}

.sidebar-widget .sidebar-widget-tag-2 ul li a:hover {
  color: #BB9B1F;
  text-decoration: underline;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 {
  position: relative;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input {
  border: 1px solid #262626;
  height: 70px;
  color: #181818;
  font-family: "Proxima Nova Rg";
  padding: 2px 50px 2px 30px;
  width: 100%;
  background-color: transparent;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input::-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .mc-news-2 {
  display: none;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.sidebar-widget .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 input {
  font-size: 0px;
  border: none;
  padding: 5px 28px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/mail.svg") no-repeat scroll right 20px center;
}

.sidebar-widget-instagram {
  margin-top: 37px;
}

.sidebar-widget-instagram.instagram-mrg-dec {
  margin-top: 28px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-instagram {
    margin-top: 28px;
  }
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.shop-page-wrap {
  margin: 0 0 0 23px;
}

.shop-page-wrap.margin-none {
  margin: 0 0 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-page-wrap {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-page-wrap {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-page-wrap {
    margin: 0 0 0 0px;
  }
}

.sidebar-wrapper-mr {
  margin-right: 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper-mr {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper-mr {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper-mr {
    margin-right: 0px;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper-mr {
    margin-right: 0px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-mrg-responsive {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-mrg-responsive {
    margin-top: 50px;
  }
}

/*-------- 24. Login register style ---------*/
.map iframe {
  height: 410px;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .map iframe {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map iframe {
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .map iframe {
    height: 300px;
  }
}

.contact-form-wrap .contact-form-title {
  margin: 0 0 27px;
}

.contact-form-wrap .contact-form-title h1, .contact-form-wrap .contact-form-title .h1 {
  font-size: 46px;
  line-height: 1;
  margin: 0 0 6px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap .contact-form-title h1, .contact-form-wrap .contact-form-title .h1 {
    font-size: 35px;
  }
}

.contact-form-wrap .contact-form-title p {
  color: #181818;
  margin: 0;
  font-family: "Proxima Nova Rg";
}

.contact-form-wrap .contact-form-style {
  margin-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap .contact-form-style {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap .contact-form-style {
    margin-right: 0px;
  }
}

.contact-form-wrap .contact-form-style input, .contact-form-wrap .contact-form-style textarea {
  height: 82px;
  width: 100%;
  color: #181818;
  font-family: "Proxima Nova Rg";
  border: 1px solid #D4D4D4;
  padding: 2px 24px;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap .contact-form-style input, .contact-form-wrap .contact-form-style textarea {
    height: 65px;
  }
}

.contact-form-wrap .contact-form-style input::-moz-input-placeholder, .contact-form-wrap .contact-form-style textarea::-moz-input-placeholder {
  opacity: 1;
  color: #181818;
}

.contact-form-wrap .contact-form-style input::-webkit-input-placeholder, .contact-form-wrap .contact-form-style textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #181818;
}

.contact-form-wrap .contact-form-style textarea {
  height: 205px;
  padding: 35px 24px 2px 24px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap .contact-form-style textarea {
    height: 150px;
    padding: 25px 24px 2px 24px;
  }
}

.contact-form-wrap .contact-form-style button.submit {
  color: #ffffff;
  background-color: #2F2F2F;
  padding: 24px 10px;
  text-align: center;
  width: 100%;
  font-family: "Proxima Nova Rg";
  border: none;
}

.contact-form-wrap .contact-form-style button.submit:hover {
  background-color: #BB9B1F;
}

.contact-info-area {
  margin: 0 0 0 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-area {
    margin: 0 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area {
    margin: 50px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area {
    margin: 40px 0 0 0px;
  }
}

.contact-info-area ul li {
  display: flex;
  align-items: center;
  margin: 0 0 47px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area ul li {
    margin: 0 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area ul li {
    margin: 0 0 30px;
  }
}

.contact-info-area ul li:last-child {
  margin: 0 0 0px;
}

.contact-info-area ul li svg.icon-width-1 {
  width: 28px;
}

.contact-info-area ul li svg.icon-width-2 {
  width: 30px;
}

.contact-info-area ul li svg.icon-width-4 {
  width: 32px;
}

.contact-info-area ul li svg path {
  stroke: #D98719;
}

.contact-info-area ul li a svg.icon-width-3 {
  width: 27px;
}

.contact-info-area ul li span {
  font-family: "Proxima Nova Rg";
  display: inline-block;
  margin-left: 29px;
  line-height: 19px;
}

.contact-info-social {
  margin: 48px 0 0;
}

@media only screen and (max-width: 767px) {
  .contact-info-social {
    margin: 30px 0 0;
  }
}

.contact-info-social a {
  margin-right: 30px;
}

.contact-info-social a:last-child {
  margin-right: 0;
}

.contact-info-social a svg g path {
  fill: #181818;
  transition: all .3s ease 0s;
}

.contact-info-social a:hover svg g path {
  fill: #BB9B1F;
}

.contact-us-wrap {
  margin: 120px 26px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-us-wrap {
    margin: 120px 0px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-wrap {
    margin: 100px 0px 0;
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-wrap {
    margin: 40px 0px 0;
  }
}

.contact-us-wrap p.form-messege {
  margin: 10px 0 0;
}

/*-------- 24. Login register style ---------*/
.coming-soon-area {
  height: 1200px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area {
    height: 1100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area {
    height: 1100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area {
    height: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area {
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area {
    height: 100vh;
  }
}

.coming-soon-area .coming-soon-content {
  margin: 345px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content {
    margin: 245px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content {
    margin: 245px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content {
    margin: 180px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content {
    margin: 50px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content {
    margin: 50px 0 0;
  }
}

.coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
  font-size: 100px;
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
    font-size: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
    font-size: 33px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-area .coming-soon-content h1, .coming-soon-area .coming-soon-content .h1 {
    font-size: 45px;
  }
}

.coming-soon-area .coming-soon-content .timer-style-1 {
  margin: 41px 0 96px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content .timer-style-1 {
    margin: 40px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content .timer-style-1 {
    margin: 40px 0 10px;
  }
}

.coming-soon-area .coming-soon-content .timer-style-1 > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.coming-soon-area .coming-soon-content .timer-style-1 > div > .cdown {
  margin: 0 53px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content .timer-style-1 > div > .cdown {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content .timer-style-1 > div > .cdown {
    margin: 0 30px 30px;
  }
}

.coming-soon-area .coming-soon-content .timer-style-1 > div > .cdown > span {
  font-size: 49px;
  color: #ffffff;
  display: block;
  line-height: 33px;
  margin: 0 0 6px;
}

.coming-soon-area .coming-soon-content .timer-style-1 > div > .cdown > p {
  font-size: 18px;
  color: #ffffff;
  display: block;
  margin: 0;
  font-family: inherit;
}

.coming-soon-area .coming-soon-content .coming-soon-btn button {
  font-size: 24px;
  color: #ffffff;
  border: 2px solid #E8CCB4;
  font-family: "Proxima Nova Rg";
  background-color: transparent;
  padding: 32px 117px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content .coming-soon-btn button {
    padding: 25px 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content .coming-soon-btn button {
    padding: 20px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content .coming-soon-btn button {
    font-size: 22px;
    padding: 11px 50px;
  }
}

.coming-soon-area .coming-soon-content .coming-soon-btn button:hover {
  background-color: #ffffff;
  color: #000;
}

.coming-soon-social {
  display: flex;
  justify-content: center;
  margin: 234px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-social {
    margin: 134px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-social {
    margin: 134px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-social {
    margin: 120px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-social {
    margin: 80px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-social {
    margin: 50px 0 0;
  }
}

.coming-soon-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  border: 1px solid #ffffff;
  border-radius: 100%;
  text-align: center;
  margin: 0 11px;
}

.coming-soon-social a svg path {
  fill: #ffffff;
  transition: all .3s ease 0s;
}

.coming-soon-social a:hover {
  background-color: #ffffff;
}

.coming-soon-social a:hover svg path {
  fill: #000;
}

/*-------- 24. Login register style ---------*/
.about-us-img {
  margin: 0 0 0 7px;
}

.about-us-img img {
  width: 100%;
}

.about-us-content {
  margin: 26px 0 0;
}

@media only screen and (max-width: 767px) {
  .about-us-content {
    margin: 0px 0 0;
  }
}

.about-us-content h1, .about-us-content .h1 {
  font-size: 46px;
  line-height: 65px;
  margin: 0 0 24px;
  width: 75%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content h1, .about-us-content .h1 {
    width: 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content h1, .about-us-content .h1 {
    font-size: 42px;
    line-height: 55px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h1, .about-us-content .h1 {
    font-size: 43px;
    line-height: 45px;
    margin: 0 0 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content h1, .about-us-content .h1 {
    font-size: 35px;
    line-height: 45px;
    margin: 0 0 15px;
    width: 100%;
  }
}

.about-us-content p {
  color: #6D6D6D;
  font-size: 18px;
  line-height: 36px;
  width: 90%;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content p {
    width: 100%;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content p {
    font-size: 17px;
    line-height: 32px;
    width: 100%;
    margin: 0 0 30px;
  }
}

.skill-img {
  margin: 0 55px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-img {
    margin: 0 0px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-img {
    margin: 0 0px 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .skill-img {
    margin: 0 0px 20px 0;
  }
}

.skill-img img {
  width: 100%;
}

.skill-content {
  margin: 0 0 0 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .skill-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content {
    margin: 0 0 0 0px;
  }
}

.skill-content h2, .skill-content .h2 {
  font-size: 46px;
  letter-spacing: -1.64px;
  margin: 0 0 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-content h2, .skill-content .h2 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content h2, .skill-content .h2 {
    font-size: 34px;
    margin: 0 0 15px;
  }
}

.skill-content p {
  font-size: 18px;
  line-height: 36px;
  margin: 0;
  color: #6D6D6D;
}

@media only screen and (max-width: 767px) {
  .skill-content p {
    font-size: 17px;
    line-height: 34px;
  }
}

.skill-bar {
  overflow: hidden;
  padding-top: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar {
    padding-top: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-bar {
    padding-top: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-bar {
    padding-top: 30px;
  }
}

.skill-bar .skill-bar-item {
  padding-bottom: 51px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 30px;
  }
}

.skill-bar .skill-bar-item:last-child {
  padding-bottom: 0px;
}

.skill-bar .skill-bar-item > span {
  color: #6D6D6D;
  font-size: 18px;
  text-align: center;
  font-family: "Proxima Nova Rg";
  z-index: 2;
}

.skill-bar .skill-bar-item .progress {
  background: #D8D8D8 none repeat scroll 0 0;
  border-radius: 0;
  box-shadow: none;
  height: 2px;
  margin-top: 7px;
  overflow: visible;
}

.skill-bar .skill-bar-item .progress .progress-bar {
  background: #181818 none repeat scroll 0 0;
  box-shadow: none;
  overflow: inherit;
  position: relative;
}

.skill-bar .skill-bar-item .progress .progress-bar span.text-top {
  color: #6D6D6D;
  font-size: 18px;
  position: absolute;
  font-family: "Proxima Nova Rg";
  right: 0;
  top: -34px;
}

.skill-bar .skill-bar-item:nth-child(1) .progress-bar {
  width: 90%;
}

.skill-bar .skill-bar-item:nth-child(2) .progress-bar {
  width: 75%;
}

.skill-bar .skill-bar-item:nth-child(3) .progress-bar {
  width: 90%;
}

.skill-area {
  margin: 232px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .skill-area {
    margin: 132px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-area {
    margin: 132px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-area {
    margin: 100px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .skill-area {
    margin: 70px 0 0;
  }
}

.team-area {
  margin: 217px 0 152px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .team-area {
    margin: 117px 0 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-area {
    margin: 117px 0 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area {
    margin: 100px 0 52px;
  }
}

@media only screen and (max-width: 767px) {
  .team-area {
    margin: 70px 0 20px;
  }
}

.team-margin-wrap {
  margin: 0 51px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-margin-wrap {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-margin-wrap {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .team-margin-wrap {
    margin: 0 0px;
  }
}

.team-wrap .team-img {
  margin: 0 0 25px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .team-wrap .team-img {
    margin: 0 0 20px;
  }
}

.team-wrap .team-img img {
  width: 100%;
  transition: all .5s ease 0s;
}

.team-wrap .team-content h3, .team-wrap .team-content .h3 {
  font-size: 24px;
  letter-spacing: -0.86px;
  margin: 0 0 9px;
  line-height: 1;
}

.team-wrap .team-content span {
  display: block;
  color: #BB9B1F;
  font-size: 18px;
  letter-spacing: -0.64px;
  font-family: "Proxima Nova Rg";
}

.team-wrap:hover .team-img img {
  transform: scale(1.1);
}

.padding-64-row-col .row {
  margin-right: -32px;
  margin-left: -32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-64-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-64-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-64-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-64-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-64-row-col .row div[class^="col-"] {
  padding-left: 32px;
  padding-right: 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-64-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-64-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-64-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-64-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*-------- 24. Login register style ---------*/
.blog-wrap.blog-wrap-border {
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 75px;
  padding-bottom: 60px;
}

.blog-wrap .blog-img {
  overflow: hidden;
  position: relative;
}

.blog-wrap .blog-img a {
  display: block;
}

.blog-wrap .blog-img a img {
  width: 100%;
  transition: all .4s ease 0s;
}

.blog-wrap .blog-img .video-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.blog-wrap .blog-img .video-popup a {
  display: inline-block;
  width: 74px;
  height: 74px;
  text-align: center;
  border: solid 4px #ffffff;
  background: rgba(216, 216, 216, 0.25);
  border-radius: 100%;
  line-height: 64px;
  transition: all .3s ease 0s;
}

.blog-wrap .blog-img .video-popup a svg path {
  fill: #FFFFFF;
  transition: all .3s ease 0s;
}

.blog-wrap .blog-img .video-popup a:hover {
  background: white;
}

.blog-wrap .blog-img .video-popup a:hover svg path {
  fill: #000;
}

.blog-wrap .blog-img-slider a {
  display: block;
}

.blog-wrap .blog-img-slider a img {
  width: 100%;
}

.blog-wrap .quote-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.blog-wrap .quote-content h3, .blog-wrap .quote-content .h3 {
  font-size: 32px;
  color: #ffffff;
  margin: 0 0 14px;
}

.blog-wrap .quote-content h3 a, .blog-wrap .quote-content .h3 a {
  color: #ffffff;
}

.blog-wrap .quote-content span {
  font-size: 16px;
  color: #ffffff;
}

.blog-wrap .blog-content .blog-category a {
  color: #BB9B1F;
  font-family: "Proxima Nova Rg";
}

.blog-wrap .blog-content .blog-category a:hover {
  text-decoration: underline;
}

.blog-wrap .blog-content h2, .blog-wrap .blog-content .h2 {
  font-size: 28px;
  margin: 12px 0 20px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h2, .blog-wrap .blog-content .h2 {
    font-size: 26px;
  }
}

.blog-wrap .blog-content p {
  color: #6D6D6D;
  font-size: 18px;
  line-height: 36px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content p {
    font-size: 16px;
    line-height: 32px;
  }
}

.blog-wrap .blog-content .blog-btn-date-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
}

.blog-wrap .blog-content .blog-btn-date-wrap .blog-btn a.btn {
  border: 1px solid #979797;
  font-family: "Proxima Nova Rg";
  padding: 14px 23px 13px;
  display: inline-block;
  font-size: 16px;
}

.blog-wrap .blog-content .blog-btn-date-wrap .blog-btn a.btn:hover {
  border: 1px solid #BB9B1F;
  color: #ffffff;
  background-color: #BB9B1F;
}

.blog-wrap .blog-content .blog-btn-date-wrap .blog-date a {
  display: inline-block;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
}

.blog-wrap:hover .blog-img a img {
  transform: scale(1.1);
}

.nav-style-3 span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 9;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #979797;
  background-color: #ffffff;
  transition: all .3s ease 0s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.nav-style-3 span svg path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.nav-style-3 span.product-icon-next-2 {
  left: auto;
  right: 20px;
}

.nav-style-3 span:hover {
  background-color: #BB9B1F;
  border: 1px solid #BB9B1F;
}

.nav-style-3 span:hover svg path {
  stroke: #ffffff;
}

.nav-style-3:hover span {
  opacity: 1;
  visibility: visible;
}

.blog-right-wrap {
  margin-left: 95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-right-wrap {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-right-wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-right-wrap {
    margin-left: 0px;
  }
}

.blog-details-wrap {
  margin-left: 95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrap {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap {
    margin-left: 0px;
  }
}

.blog-details-wrap img {
  width: 100%;
}

.blog-details-wrap > span {
  font-size: 16px;
  color: #BB9B1F;
  display: block;
  font-family: "Proxima Nova Rg";
  margin: 22px 0 0;
}

.blog-details-wrap h1, .blog-details-wrap .h1 {
  font-size: 28px;
  margin: 12px 0 14px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap h1, .blog-details-wrap .h1 {
    font-size: 26px;
  }
}

.blog-details-wrap > p {
  color: #6D6D6D;
  font-size: 18px;
  line-height: 36px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap > p {
    font-size: 16px;
    line-height: 33px;
  }
}

.blog-details-wrap > p.paragraph-margin-1 {
  margin: 0 0 18px;
}

.blog-details-wrap > p.paragraph-margin-2 {
  margin: 0 0 28px;
}

.blog-details-wrap > p.paragraph-margin-3 {
  margin: 0 0 47px;
}

.blog-details-wrap > p.paragraph-margin-4 {
  margin: 0 0 28px;
}

.blog-details-wrap > p.paragraph-margin-5 {
  margin: 0 0 35px;
}

.blog-details-wrap > p.paragraph-margin-6 {
  margin: 0 0 28px;
}

.blog-details-wrap > p.paragraph-margin-7 {
  margin: 0 0 42px;
}

.blog-details-wrap > h2, .blog-details-wrap > .h2 {
  font-size: 28px;
  margin: 58px 0 25px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap > h2, .blog-details-wrap > .h2 {
    font-size: 26px;
  }
}

.blog-details-wrap blockquote {
  background-color: #f9f9f9;
  border-width: 0;
  text-align: center;
  padding: 65px 40px;
  margin: 0px 0 50px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap blockquote {
    padding: 60px 30px;
    margin: 20px 0;
  }
}

.blog-details-wrap blockquote::before {
  content: url(../../assets/images/icon-img/quote.png);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.blog-details-wrap blockquote h3, .blog-details-wrap blockquote .h3 {
  font-size: 32px;
  color: #3E3E3E;
  line-height: 45px;
  width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 26px;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap blockquote h3, .blog-details-wrap blockquote .h3 {
    font-size: 20px;
    line-height: 35px;
  }
}

.blog-details-wrap blockquote span {
  color: #3E3E3E;
  font-size: 16px;
  position: relative;
  font-family: inherit;
}

.blog-details-wrap blockquote span:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: #D8D8D8;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -13px;
}

.blog-details-wrap .blog-tag-share-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  padding: 15px 0;
}

.blog-details-wrap .blog-tag-share-wrap .blog-tag-wrap {
  display: flex;
  align-items: center;
}

.blog-details-wrap .blog-tag-share-wrap .blog-tag-wrap span {
  font-family: proximanovathin;
  margin-right: 5px;
}

.blog-details-wrap .blog-tag-share-wrap .blog-tag-wrap ul {
  display: flex;
}

.blog-details-wrap .blog-tag-share-wrap .blog-tag-wrap ul li {
  margin-right: 5px;
}

.blog-details-wrap .blog-tag-share-wrap .blog-tag-wrap ul li a {
  font-family: proximanovathin;
  display: inline-block;
  font-style: italic;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap {
  display: flex;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a {
  display: inline-block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 100%;
  text-align: center;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a {
    margin-right: 8px;
  }
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a:last-child {
  margin-right: 0;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a.facebook {
  background-color: #272EAE;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a.facebook svg {
  width: 6px;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a.twitter {
  background-color: #2290DA;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a.twitter svg {
  width: 15px;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a.youtube {
  background-color: #BB1C3F;
  line-height: 25px;
}

.blog-details-wrap .blog-tag-share-wrap .blog-share-wrap a.youtube svg {
  width: 13px;
}

.blog-details-wrap .blog-prev-next-wrap {
  display: flex;
  justify-content: space-between;
  margin: 21px 0 0;
}

.blog-details-wrap .blog-prev-next-wrap .blog-prev-wrap h5, .blog-details-wrap .blog-prev-next-wrap .blog-prev-wrap .h5 {
  font-size: 18px;
  line-height: 24px;
  font-family: "Proxima Nova Rg";
  margin-bottom: 8px;
  width: 80%;
}

.blog-details-wrap .blog-prev-next-wrap .blog-prev-wrap > a {
  display: inline-block;
  font-size: 14px;
  color: #494949;
  font-family: "Proxima Nova Rg";
}

.blog-details-wrap .blog-prev-next-wrap .blog-prev-wrap > a:hover {
  color: #BB9B1F;
}

.blog-details-wrap .blog-prev-next-wrap .blog-next-wrap {
  text-align: right;
}

.blog-details-wrap .blog-prev-next-wrap .blog-next-wrap h5, .blog-details-wrap .blog-prev-next-wrap .blog-next-wrap .h5 {
  font-size: 18px;
  line-height: 24px;
  font-family: "Proxima Nova Rg";
  margin: 0 0 8px auto;
  width: 80%;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-prev-next-wrap .blog-next-wrap h5, .blog-details-wrap .blog-prev-next-wrap .blog-next-wrap .h5 {
    font-size: 17px;
  }
}

.blog-details-wrap .blog-prev-next-wrap .blog-next-wrap > a {
  display: inline-block;
  font-size: 14px;
  color: #494949;
  font-family: "Proxima Nova Rg";
}

.blog-details-wrap .blog-prev-next-wrap .blog-next-wrap > a:hover {
  color: #BB9B1F;
}

.blog-details-wrap .blog-details-comment {
  margin: 72px 0 44px;
}

.blog-details-wrap .blog-details-comment > h2, .blog-details-wrap .blog-details-comment > .h2 {
  font-size: 24px;
  margin: 0 0 36px;
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap {
  border: 1px solid #D1D1D1;
  padding: 50px 0 44px 0px;
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment {
  display: flex;
  margin-top: 53px;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 15px;
  border-top: 1px solid #D1D1D1;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment {
    padding-left: 20px;
    margin-top: 23px;
    padding-top: 20px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment:first-child {
  border-top: none;
  margin-top: 0px;
  padding-top: 0px;
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-img {
  flex: 0 0 94px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-img {
    flex: 0 0 80px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-img img {
  width: 100%;
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content {
  margin-left: 56px;
  margin-top: 6px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content {
    margin-left: 15px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content p {
  font-size: 14px;
  line-height: 28px;
  color: #181818;
  margin: 0 0 15px;
  font-family: "Proxima Nova Lt";
  font-style: italic;
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply {
    display: flex;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply .time span {
  font-size: 12px;
  font-family: "Proxima Nova Rg";
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply .reply {
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply .reply {
    margin-left: 0px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply .reply {
    margin-left: 20px;
    margin-top: 0px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment .blog-comment-content .time-reply .reply a {
  font-size: 12px;
  font-family: "Proxima Nova Rg";
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment.single-blog-comment-mrg {
  margin-left: 170px;
  border: none;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment.single-blog-comment-mrg {
    margin-left: 20px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment.single-blog-comment-mrg:before {
  position: absolute;
  content: "";
  left: 0;
  top: 95px;
  width: 10px;
  height: 3px;
  background-color: #D4876C;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment-wrap .single-blog-comment.single-blog-comment-mrg:before {
    top: 60px;
  }
}

.blog-details-wrap .blog-comment-form h2, .blog-details-wrap .blog-comment-form .h2 {
  font-size: 24px;
  margin: 0 0 6px;
}

.blog-details-wrap .blog-comment-form p {
  font-size: 12px;
  margin: 0;
  color: #181818;
}

.blog-details-wrap .blog-comment-form form {
  margin: 42px 0 0;
}

.blog-details-wrap .blog-comment-form form .leave-form input, .blog-details-wrap .blog-comment-form form .leave-form textarea {
  border: 1px solid #D1D1D1;
  height: 60px;
  padding: 2px 20px;
  font-size: 12px;
  color: #181818;
  width: 100%;
  background-color: transparent;
  font-family: "Proxima Nova Rg";
}

.blog-details-wrap .blog-comment-form form .leave-form input::-moz-input-placeholder, .blog-details-wrap .blog-comment-form form .leave-form textarea::-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.blog-details-wrap .blog-comment-form form .leave-form input::-webkit-input-placeholder, .blog-details-wrap .blog-comment-form form .leave-form textarea::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.blog-details-wrap .blog-comment-form form .leave-form textarea {
  height: 190px;
  padding: 22px 20px 2px 20px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-comment-form form .leave-form textarea {
    height: 150px;
  }
}

.blog-details-wrap .blog-comment-form form .text-submit input {
  font-family: "Proxima Nova Rg";
  color: #ffffff;
  background-color: #181818;
  text-transform: uppercase;
  border: none;
  padding: 14px 60px;
}

.blog-details-wrap .blog-comment-form form .text-submit input:hover {
  background-color: #BB9B1F;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area.pb-145 {
    padding-bottom: 10px;
  }
  .blog-details-area.pb-150 {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area.pb-145 {
    padding-bottom: 0px;
  }
  .blog-details-area.pb-150 {
    padding-bottom: 5px;
  }
}

/*-------- 24. Login register style ---------*/
.product-details-tab {
  margin-right: -8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-tab {
    margin-right: 0px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-tab {
    margin-right: 0px;
    margin-bottom: 50px;
  }
}

.product-details-content.product-details-mrg-left {
  margin: 0 0 0 73px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content.product-details-mrg-left {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content.product-details-mrg-left {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.product-details-mrg-left {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.product-details-mrg-left {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.product-details-mrg-left {
    margin: 0 0 0 0px;
  }
}

.product-details-content.quickview-content {
  padding: 20px 30px 0 0;
}

.product-details-content.quickview-content .product-details-quality-cart {
  margin: 32px 0 40px;
}

.product-details-content.quickview-content .product-details-meta-wrap {
  margin: 35px 0 0px;
}

.product-details-content .product-rating-stock-review {
  display: flex;
  align-items: center;
}

.product-details-content .product-rating-stock-review .product-rating {
  display: flex;
}

.product-details-content .product-rating-stock-review .product-rating a {
  margin-right: 4px;
}

.product-details-content .product-rating-stock-review .product-rating a.yellow svg path {
  fill: #F5A623;
}

.product-details-content .product-rating-stock-review .product-review {
  margin: 0 20px 0 5px;
}

.product-details-content .product-rating-stock-review .product-review span {
  color: #262626;
  font-size: 10px;
  font-family: "Proxima Nova Rg";
}

.product-details-content .product-rating-stock-review .product-stock {
  display: flex;
  align-items: center;
}

.product-details-content .product-rating-stock-review .product-stock a {
  display: inline-block;
  margin-right: 6px;
}

.product-details-content .product-rating-stock-review .product-stock span {
  font-family: "Proxima Nova Lt";
  font-size: 12px;
}

.product-details-content > h2, .product-details-content > .h2 {
  font-family: "Proxima Nova Lt";
  font-size: 28px;
  line-height: 1;
  margin: 20px 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content > h2, .product-details-content > .h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content > h2, .product-details-content > .h2 {
    font-size: 18px;
  }
}

.product-details-content .product-details-price span {
  color: #BB9B1F;
  font-size: 24px;
  display: inline-block;
  font-family: "Proxima Nova Lt";
}

.product-details-content p {
  font-size: 14px;
  line-height: 28px;
  margin: 8px 0 0;
}

.product-details-content .product-details-color {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-color span {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  margin: 6px 12px 0 0;
}

.product-details-content .product-details-color ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-details-content .product-details-color ul li {
  margin-right: 13px;
  margin-bottom: 5px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-color ul li {
    margin-right: 12px;
  }
}

.product-details-content .product-details-color ul li:last-child {
  margin-right: 0;
}

.product-details-content .product-details-color ul li a {
  width: 17px;
  height: 17px;
  font-size: 0;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.product-details-content .product-details-color ul li a.white {
  background-color: #ffffff;
  border: 1px solid #979797;
}

.product-details-content .product-details-color ul li a.pink {
  background-color: #F46C7D;
}

.product-details-content .product-details-color ul li a.yellow {
  background-color: #BB9B1F;
}

.product-details-content .product-details-color ul li a.black {
  background-color: #4D4D4D;
}

.product-details-content .product-details-color ul li a.blue {
  background-color: #6CB8F4;
}

.product-details-content .product-details-quality-cart {
  display: flex;
  align-items: center;
  margin: 55px 0 40px;
}

.product-details-content .product-details-quality-cart .product-details-cart {
  margin-left: 10px;
}

.product-details-content .product-details-quality-cart .product-details-cart a {
  background-color: #181818;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
  padding: 20px 74px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 20px 40px;
  }
}

.product-details-content .product-details-quality-cart .product-details-cart a:hover {
  background-color: #BB9B1F;
}

.product-details-content .product-details-wishlist-compare {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-wishlist-compare .product-details-wishlist {
  margin-right: 22px;
}

.product-details-content .product-details-wishlist-compare .product-details-wishlist a {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
  display: flex;
  align-items: center;
}

.product-details-content .product-details-wishlist-compare .product-details-wishlist a svg {
  margin-right: 10px;
}

.product-details-content .product-details-wishlist-compare .product-details-compare a {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
  display: flex;
  align-items: center;
}

.product-details-content .product-details-wishlist-compare .product-details-compare a svg {
  margin-right: 10px;
}

.product-details-content .product-details-meta-wrap {
  margin: 35px 0 44px;
}

.product-details-content .product-details-meta-wrap .product-details-meta {
  display: flex;
  margin: 0 0 6px;
}

.product-details-content .product-details-meta-wrap .product-details-meta span {
  font-size: 14px;
  font-family: "Proxima Nova Rg";
  margin-right: 5px;
}

.product-details-content .product-details-meta-wrap .product-details-meta ul li {
  display: inline-block;
  font-size: 14px;
  font-family: "Proxima Nova Rg";
}

.product-details-content .product-details-meta-wrap .product-details-meta ul li a {
  font-size: 14px;
  font-family: "Proxima Nova Rg";
}

.product-details-content .product-details-social a {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 29px;
  border-radius: 100%;
  text-align: center;
  border: 1px solid #E1E1E1;
  margin-right: 12px;
}

.product-details-content .product-details-social a:hover {
  border: 1px solid #BB9B1F;
}

.product-details-content .product-details-social a.facebook svg, .product-details-content .product-details-social a.facebook img {
  width: 10px;
}

.product-details-content .product-details-social a.twitter svg, .product-details-content .product-details-social a.twitter img {
  width: 16px;
}

.product-details-content .product-details-social a.pinterest svg, .product-details-content .product-details-social a.pinterest img {
  width: 14px;
}

.description-review-wrapper .tab-style-2 {
  justify-content: center;
  border-bottom: 1px solid #D8D8D8;
  border-top: 1px solid #D8D8D8;
  padding: 19px 0 18px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .tab-style-2 {
    padding: 19px 0 3px;
  }
}

.description-review-wrapper .tab-style-2 a {
  color: #6D6D6D;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  margin: 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-wrapper .tab-style-2 a {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-wrapper .tab-style-2 a {
    margin: 0 12px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .tab-style-2 a {
    margin: 0 8px 15px;
    font-size: 14px;
  }
}

.description-review-wrapper .tab-style-2 a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -20px;
  width: 100%;
  height: 3px;
  background-color: #BB9B1F;
  border-radius: 3px;
  margin: 0 auto;
  transform: scale(0);
  transition: all .5s ease 0s;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .tab-style-2 a:before {
    bottom: -5px;
  }
}

.description-review-wrapper .tab-style-2 a.active {
  color: #BB9B1F;
}

.description-review-wrapper .tab-style-2 a.active:before {
  transform: scale(1);
}

.pro-description-banner img {
  width: 100%;
}

.pro-description-content {
  margin: 30px 0 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-description-content {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-description-content {
    margin: 40px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-description-content {
    margin: 40px 0 0 0px;
  }
}

.pro-description-content h2, .pro-description-content .h2 {
  font-size: 32px;
  letter-spacing: -1.14px;
  margin: 0 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-description-content h2, .pro-description-content .h2 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-description-content h2, .pro-description-content .h2 {
    margin: 0 0 20px;
  }
}

.pro-description-content p {
  line-height: 36px;
  color: #6D6D6D;
  font-size: 18px;
  margin: 0 0 17px;
}

@media only screen and (max-width: 767px) {
  .pro-description-content p {
    font-size: 16px;
    line-height: 33px;
  }
}

.specification-wrap table {
  width: 100%;
}

.specification-wrap table tbody {
  border: 1px solid #ebebeb;
  width: 100%;
}

.specification-wrap table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.specification-wrap table tbody tr td {
  border-right: 1px solid #ebebeb;
  font-size: 16px;
  padding: 12px 10px;
  font-family: "Proxima Nova Rg";
}

@media only screen and (max-width: 767px) {
  .specification-wrap table tbody tr td {
    padding: 12px 15px;
  }
}

.specification-wrap table tbody tr td.width1 {
  width: 215px;
}

@media only screen and (max-width: 767px) {
  .specification-wrap table tbody tr td.width1 {
    width: auto;
  }
}

.review-wrapper h3, .review-wrapper .h3 {
  font-size: 24px;
  margin: 0 0 37px;
  line-height: 20px;
}

@media only screen and (max-width: 767px) {
  .review-wrapper h3, .review-wrapper .h3 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.single-review {
  margin: 0 0 60px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 34px;
}

@media only screen and (max-width: 767px) {
  .single-review {
    display: block;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }
}

.single-review .review-img img {
  width: 100px;
}

.single-review .review-content {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

.single-review .review-content h5, .single-review .review-content .h5 {
  font-size: 12px;
  margin: 11px 0 8px;
  font-family: "Proxima Nova Rg";
}

.single-review .review-content h5 span, .single-review .review-content .h5 span {
  font-size: 14px;
  font-family: "Proxima Nova Rg";
}

.single-review .review-content .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-rating {
    margin: 10px 0 0px;
  }
}

.single-review .review-content .review-rating a.yellow svg path {
  fill: #F5A623;
}

.single-review .review-content p {
  line-height: 25px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > h3, .ratting-form-wrapper > .h3 {
  display: block;
  line-height: 1;
  font-size: 24px;
}

.ratting-form-wrapper > p {
  margin: 35px 0 0;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper > p {
    margin: 15px 0 0;
  }
}

.ratting-form-wrapper .your-rating-wrap {
  display: flex;
  align-items: center;
  margin: 52px 0 0px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .your-rating-wrap {
    margin: 20px 0 0px;
  }
}

.ratting-form-wrapper .your-rating-wrap span {
  font-size: 16px;
  display: inline-block;
  margin-right: 20px;
}

.ratting-form-wrapper .your-rating-wrap .your-rating a.yellow svg path {
  fill: #F5A623;
}

.ratting-form-wrapper .ratting-form {
  margin: 19px 0 0;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 14px;
  font-size: 16px;
  display: block;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #ebebeb;
  height: 50px;
  padding: 5px 10px;
  width: 100%;
}

.ratting-form-wrapper .ratting-form .rating-form-style input:focus, .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #181818;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .ratting-form .rating-form-style textarea {
    height: 100px;
  }
}

.ratting-form-wrapper .ratting-form .save-email-option > p {
  display: flex;
  align-items: center;
  margin: 0 0 5px;
}

.ratting-form-wrapper .ratting-form .save-email-option > p input {
  width: auto;
  height: auto;
}

.ratting-form-wrapper .ratting-form .save-email-option label {
  margin: 0 0 0 16px;
}

.ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 9px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 15px 65px;
  color: #fff;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 16px;
  text-transform: uppercase;
  background: #181818;
  border: none;
  letter-spacing: 1px;
  font-family: "Proxima Nova Rg";
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #BB9B1F;
}

.vendor-info-content h3, .vendor-info-content .h3 {
  font-size: 24px;
  margin: 0 0 33px;
}

@media only screen and (max-width: 767px) {
  .vendor-info-content h3, .vendor-info-content .h3 {
    margin: 0 0 15px;
  }
}

.vendor-info-content ul li {
  font-size: 15px;
  margin: 0 0 10px;
  line-height: 28px;
  font-family: "Proxima Nova Rg";
}

@media only screen and (max-width: 767px) {
  .vendor-info-content ul li {
    font-size: 14px;
    line-height: 25px;
  }
}

.vendor-info-content ul li span {
  display: inline-block;
  margin-right: 5px;
  margin-top: -7px;
  line-height: 1;
}

.vendor-info-content ul li span svg path {
  fill: #F5A623;
}

.vendor-info-content ul li.rating {
  display: flex;
  align-items: center;
}

.about-brand-wrap p {
  font-size: 18px;
  line-height: 36px;
  color: #6D6D6D;
  margin: 0 0 20px;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.product-dec-small-style1 {
  width: 490px;
  margin: 27px auto 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-small-style1 {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

.product-dec-small-style1 .product-dec-small {
  margin: 0 10px 0px 10px;
  cursor: pointer;
}

.product-dec-small-style1 .product-dec-small img {
  width: 100%;
  opacity: .4;
}

.product-dec-small-style1 .product-dec-small.slick-active.slick-current img {
  opacity: 1;
}

.product-dec-small-style1 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style1 span {
  font-size: 10px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  width: 23px;
  height: 23px;
  line-height: 22px;
  border: 1px solid #979797;
  color: #1E1E1E;
  text-align: center;
  border-radius: 100%;
}

.product-dec-small-style1 span:hover {
  color: #ffffff;
  border: 1px solid #BB9B1F;
  background-color: #BB9B1F;
}

.product-dec-small-style1 span.pro-dec-prev {
  left: -0.7%;
}

.product-dec-small-style1 span.pro-dec-next {
  left: auto;
  right: -0.7%;
}

.back-to-shop {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 95px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .back-to-shop {
    margin: 0 0 63px;
  }
}

@media only screen and (max-width: 767px) {
  .back-to-shop {
    margin: 0 0 25px;
    justify-content: center;
  }
}

.back-to-shop a {
  font-size: 18px;
  font-family: "Proxima Nova Rg";
  display: flex;
  align-items: center;
}

.back-to-shop a svg {
  margin-right: 22px;
}

.back-to-shop a svg g path {
  stroke: #1E1E1E;
  transition: all .3s ease 0s;
}

.back-to-shop a img {
  margin-right: 22px;
}

.back-to-shop a:hover svg g path {
  stroke: #BB9B1F;
}

.quickview-img a {
  display: block;
}

.quickview-img a img {
  width: 100%;
}

.modal-dialog {
  margin: 9% auto 8%;
  max-width: 1133px;
  width: 1170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
  padding: 30px 30px 27px;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content {
    padding: 20px 20px 27px;
  }
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: 30px;
  top: 20px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog .modal-content .modal-header {
    right: 8px;
    top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    position: static;
    justify-content: center;
    margin: 0 0 10px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #181818;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  border: 0;
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #BB9B1F;
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
}

.product-quality {
  width: 83px;
  overflow: hidden;
  position: relative;
  border: 1px solid #D8D8D8;
}

.product-quality > input {
  width: 83px;
  border: none;
  height: 60px;
  line-height: 50px;
  text-align: center;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  color: #181818;
  padding: 0;
}

.product-quality > input::-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.product-quality > input::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.product-quality .qtybutton {
  position: absolute;
  color: #D8D8D8;
  left: 9px;
  font-family: "Proxima Nova Lt";
  cursor: pointer;
}

.product-quality .qtybutton.inc {
  left: auto;
  right: 9px;
  font-size: 20px;
  top: 15px;
}

.product-quality .qtybutton.dec {
  top: -2px;
  font-size: 40px;
  font-family: "Proxima Nova Rg";
}

.product-details-size {
  margin: 34px 161px 16px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-size {
    margin: 34px 83px 16px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-size {
    margin: 34px 126px 16px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-size {
    margin: 34px 27px 16px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-size {
    margin: 34px 344px 16px 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-size {
    margin: 34px 55px 16px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-size {
    margin: 34px 270px 16px 0;
  }
}

.product-details-size .nice-select-style-1 {
  border: none;
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  padding: 0;
  height: auto;
  line-height: 27px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-down-3.png") no-repeat scroll right 0px center;
}

.product-details-size .nice-select-style-1.open {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-up-1.png") no-repeat scroll right 0px center;
}

.product-details-size .nice-select-style-1.nice-select::after {
  display: none;
}

.product-details-size .nice-select-style-1 ul {
  z-index: 9 !important;
  width: 100%;
}

.product-details-size .nice-select-style-1 ul li {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-area.pb-115 {
    padding-bottom: 100px;
  }
  .description-review-area.pb-180 {
    padding-bottom: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-area.pb-115 {
    padding-bottom: 70px;
  }
  .description-review-area.pb-180 {
    padding-bottom: 55px;
  }
  .related-product.pb-100 {
    padding-bottom: 65px;
  }
}

/*-------- 16. Cart style ---------*/
.cart-table-content .table-content table {
  width: 100%;
}

.cart-table-content .table-content table thead {
  background-color: #f3f3f3;
}

.cart-table-content .table-content table thead > tr th {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 14px 23px 14px;
  font-family: "Proxima Nova Lt";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th {
    padding: 14px 8px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th {
    padding: 14px 8px 14px;
  }
}

.cart-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 90px;
  }
}

.cart-table-content .table-content table thead > tr th.width-name {
  min-width: 400px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-price {
  min-width: 162px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-quantity {
  min-width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

.cart-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-remove {
  min-width: 101px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

.cart-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #e5e5e5;
}

.cart-table-content .table-content table tbody > tr td {
  padding: 23px;
  font-family: "Proxima Nova Rg";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td {
    padding: 23px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td {
    padding: 23px 8px;
  }
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 80px;
}

.cart-table-content .table-content table tbody > tr td.product-name h5, .cart-table-content .table-content table tbody > tr td.product-name .h5 {
  display: block;
  font-size: 15px;
  color: #000;
  font-family: "Proxima Nova Rg";
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a, .cart-table-content .table-content table tbody > tr td.product-name .h5 a {
  color: #000;
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a:hover, .cart-table-content .table-content table tbody > tr td.product-name .h5 a:hover {
  color: #BB9B1F;
}

.cart-table-content .table-content table tbody > tr td.product-price span, .cart-table-content .table-content table tbody > tr td.product-total span {
  font-size: 16px;
}

.cart-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 18px;
  color: #000;
  display: inline-block;
}

.cart-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #BB9B1F;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 55px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
    padding: 30px 0px 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
  background-color: #f2f2f2;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  padding: 18px 40px 17px;
  text-transform: capitalize;
  letter-spacing: .3px;
  border-radius: 3px;
  font-family: "Proxima Nova Lt";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 25px 17px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 40px 17px;
    margin: 0 0 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a:hover,
.cart-shiping-update-wrapper .cart-clear > button:hover,
.cart-shiping-update-wrapper .cart-clear > a:hover {
  background-color: #BB9B1F;
  color: #fff;
}

.cart-shiping-update-wrapper .cart-clear > button {
  border: medium none;
  cursor: pointer;
  margin-right: 27px;
  border-radius: 3px;
  font-family: "Proxima Nova Lt";
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 10px;
  }
}

.cart-calculate-discount-wrap h4, .cart-calculate-discount-wrap .h4 {
  font-size: 20px;
  font-family: "Proxima Nova Lt";
  margin: 0 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-calculate-discount-wrap h4, .cart-calculate-discount-wrap .h4 {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-calculate-discount-wrap h4, .cart-calculate-discount-wrap .h4 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-calculate-discount-wrap {
    margin: 0px 0 40px;
  }
}

.select-style {
  margin-bottom: 15px;
}

.select-style select {
  width: 100%;
  font-family: "Proxima Nova Rg";
}

.select-style .select2-container--default {
  display: block;
  width: 100%;
}

.select-style .select2-container--default .select2-selection--single {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 52px;
  line-height: 52px;
}

.select-style .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 13px;
  right: 10px;
}

.select-style .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #181818;
  line-height: 52px;
  padding-left: 16px;
  padding-right: 20px;
  font-family: "Proxima Nova Rg";
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e5e5e5;
  height: 52px;
  background-color: transparent;
}

.calculate-discount-content p {
  font-size: 14px;
  margin-bottom: 18px;
  display: block;
  color: #666;
  line-height: 1;
}

.calculate-discount-content .input-style {
  margin-bottom: 15px;
}

.calculate-discount-content .input-style input {
  color: #181818;
  height: 52px;
  border: 1px solid #e5e5e5;
  background-color: transparent;
  padding: 2px 16px;
  width: 100%;
  font-family: "Proxima Nova Rg";
}

.calculate-discount-content .input-style input:-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.calculate-discount-content .input-style input:-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.calculate-discount-content .calculate-discount-btn a {
  font-size: 16px;
  background-color: #000;
  font-family: "Proxima Nova Rg";
}

.calculate-discount-content .calculate-discount-btn a.btn {
  border-radius: 3px;
  padding: 10px 19px 12px;
}

.calculate-discount-content .calculate-discount-btn a.btn-link {
  text-decoration: none;
  color: #ffffff;
}

.calculate-discount-content .calculate-discount-btn a:focus {
  box-shadow: none;
}

.calculate-discount-content .calculate-discount-btn a:hover {
  background-color: #BB9B1F;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin: 50px 0 0;
  }
}

.grand-total-wrap .grand-total-content {
  padding: 30px;
  padding: 34px 30px 33px;
  background-color: #f5f5f5;
}

.grand-total-wrap .grand-total-content h3, .grand-total-wrap .grand-total-content .h3 {
  font-size: 20px;
  margin: 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 0 0 18px;
  font-family: "Proxima Nova Lt";
}

.grand-total-wrap .grand-total-content h3 span, .grand-total-wrap .grand-total-content .h3 span {
  font-size: 16px;
  font-family: "Proxima Nova Rg";
}

.grand-total-wrap .grand-total-content .grand-shipping {
  padding: 38px 0 32px;
}

.grand-total-wrap .grand-total-content .grand-shipping > span {
  font-size: 16px;
  font-family: "Proxima Nova Lt";
}

.grand-total-wrap .grand-total-content .grand-shipping ul {
  padding: 17px 0 0px;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-family: "Proxima Nova Rg";
}

.grand-total-wrap .grand-total-content .grand-shipping ul li:last-child {
  margin-bottom: 0;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li input {
  width: auto;
  height: auto;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li label {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin-left: 10px;
}

.grand-total-wrap .grand-total-content .shipping-country {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 35px;
  margin-bottom: 30px;
}

.grand-total-wrap .grand-total-content .shipping-country p {
  display: block;
  padding: 10px 20px;
  background-color: #4bb1cf;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  line-height: 1.7;
  border-left: 6px solid #3198b6;
  font-family: "Proxima Nova Rg";
  margin: 0;
}

.grand-total-wrap .grand-total h4, .grand-total-wrap .grand-total .h4 {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-family: "Proxima Nova Lt";
}

.grand-total-wrap .grand-total h4 span, .grand-total-wrap .grand-total .h4 span {
  color: #BB9B1F;
}

.grand-total-wrap .grand-total-btn {
  margin-top: 24px;
}

.grand-total-wrap .grand-total-btn a {
  font-size: 18px;
  text-transform: capitalize;
  background-color: #181818;
  display: block;
  font-family: "Proxima Nova Lt";
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-total-btn a {
    font-size: 16px;
  }
}

.grand-total-wrap .grand-total-btn a.btn {
  padding: 15px 20px;
  border-radius: 0;
}

.grand-total-wrap .grand-total-btn a.btn-link {
  text-decoration: none;
  color: #ffffff;
}

.grand-total-wrap .grand-total-btn a:focus {
  box-shadow: none;
}

.grand-total-wrap .grand-total-btn a:hover {
  background-color: #BB9B1F;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-area.pb-130 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-area.pb-130 {
    padding-bottom: 70px;
  }
  .wishlist-area.pb-130 {
    padding-bottom: 70px;
  }
}

/*-------- 20. Wishlist style ---------*/
.wishlist-table-content .table-content table {
  width: 100%;
}

.wishlist-table-content .table-content table thead {
  background-color: #f3f3f3;
}

.wishlist-table-content .table-content table thead > tr th {
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px 12px 14px;
  font-family: "Proxima Nova Lt";
}

.wishlist-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-name {
  min-width: 300px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-price {
  min-width: 162px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-price {
    min-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-price {
    min-width: 120px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-stock-status {
  min-width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-stock-status {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-stock-status {
    min-width: 140px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-remove {
  min-width: 50px;
}

.wishlist-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #e5e5e5;
}

.wishlist-table-content .table-content table tbody > tr td {
  padding: 23px 12px;
}

.wishlist-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.wishlist-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 80px;
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5, .wishlist-table-content .table-content table tbody > tr td.product-name .h5 {
  display: block;
  font-size: 15px;
  color: #000;
  font-family: "Proxima Nova Rg";
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 a, .wishlist-table-content .table-content table tbody > tr td.product-name .h5 a {
  color: #000;
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 a:hover, .wishlist-table-content .table-content table tbody > tr td.product-name .h5 a:hover {
  color: #BB9B1F;
}

.wishlist-table-content .table-content table tbody > tr td.product-price span {
  font-size: 15px;
  font-family: "Proxima Nova Rg";
}

.wishlist-table-content .table-content table tbody > tr td.stock-status span {
  font-size: 14px;
  font-family: "Proxima Nova Rg";
}

.wishlist-table-content .table-content table tbody > tr td.stock-status span i {
  font-size: 15px;
}

.wishlist-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 28px;
  color: #c3c3c3;
  display: inline-block;
  font-family: "Proxima Nova Rg";
}

.wishlist-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #BB9B1F;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
  display: inline-block;
  font-family: "Proxima Nova Rg";
  font-size: 15px;
  color: #ffffff;
  padding: 10px 15px 10px;
  line-height: 1;
  white-space: pre;
  background-color: #BB9B1F;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 14px;
    padding: 10px 12px 9px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 14px;
    padding: 10px 12px 9px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 14px;
    padding: 10px 12px 9px;
  }
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-cart a:hover {
  background-color: #000;
}

/*-------- 24. Login register style ---------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-register-tab-list {
    margin-bottom: 20px;
  }
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
  font-family: "Proxima Nova Rg";
}

.login-register-wrapper .login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a h4, .login-register-wrapper .login-register-tab-list a .h4 {
  font-size: 27px;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  font-family: "Proxima Nova Lt";
}

.login-register-wrapper .login-register-tab-list a:last-child::before {
  display: none;
}

.login-register-wrapper .login-register-tab-list a.active h4, .login-register-wrapper .login-register-tab-list a.active .h4 {
  color: #BB9B1F;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-size: 15px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #181818;
  width: 100%;
  font-family: "Proxima Nova Rg";
}

.login-register-wrapper .login-form-container .login-register-form form input::-moz-input-placeholder {
  opacity: 1;
  color: #181818;
}

.login-register-wrapper .login-form-container .login-register-form form input::-webkit-input-placeholder {
  opacity: 1;
  color: #181818;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
  color: #181818;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"]:-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"]:-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
  font-family: "Proxima Nova Rg";
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
  float: right;
  font-size: 15px;
  color: #181818;
  font-family: "Proxima Nova Rg";
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
  color: #BB9B1F;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button {
  background-color: #BB9B1F;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 14px 30px 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  font-family: "Proxima Nova Rg";
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
  background-color: #181818;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-area.pb-130 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-area.pb-130 {
    padding-bottom: 70px;
  }
}

/*-------- 22. Checkout style ---------*/
.customer-zone > p {
  font-size: 15px;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  position: relative;
  color: #000;
}

.customer-zone > p a {
  color: #000;
}

.customer-zone > p a:hover {
  color: #BB9B1F;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}

.customer-zone > p:before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: 'Line Awesome Free';
  left: 15px;
  top: 15px;
  font-size: 18px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}

.checkout-login-info p {
  margin: 0 0 12px;
  color: #6d6d6d;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  font-size: 15px;
  text-transform: capitalize;
  margin: 0 0 5px;
  font-family: "Proxima Nova Rg";
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 100%;
  font-family: "Proxima Nova Rg";
  padding: 2px 20px;
}

.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  border: 1px solid #aaa;
  font-size: 13px;
  padding: 10px 30px 9px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  font-family: "Proxima Nova Lt";
  text-transform: uppercase;
}

.checkout-login-info form .button-remember-wrap button:hover {
  background-color: #BB9B1F;
  color: #fff;
  border: 1px solid #BB9B1F;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
  font-family: "Proxima Nova Rg";
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  width: 100%;
  font-family: "Proxima Nova Rg";
  font-size: 15px;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  font-size: 15px;
  font-family: "Proxima Nova Rg";
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}

.checkout-login-info2 ul li {
  display: inline-block;
}

.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info2 ul li a:hover {
  color: #BB9B1F;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}

.checkout-login-info3 form input[type="text"] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  font-size: 14px;
  padding: 0 20px;
  font-family: "Proxima Nova Rg";
}

.checkout-login-info3 form input[type="text"]:focus {
  border: 1px solid #1f2226;
}

.checkout-login-info3 form input[type="submit"] {
  background: #1f2226 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 13px;
  text-transform: uppercase;
  width: inherit;
  font-family: "Proxima Nova Rg";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 16px;
  }
}

.checkout-login-info3 form input[type="submit"]:hover {
  background-color: #BB9B1F;
}

.billing-info-wrap h3, .billing-info-wrap .h3 {
  font-size: 20px;
  position: relative;
  margin: 0 0 55px;
  font-family: "Proxima Nova Lt";
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3, .billing-info-wrap .h3 {
    margin-bottom: 40px;
  }
}

.billing-info-wrap h3:before, .billing-info-wrap .h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #BB9B1F;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 14px;
  width: 100%;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select select {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #181818;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-down-3.png") no-repeat scroll right 18px center;
  cursor: pointer;
  width: 100%;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .billing-select select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  color: #181818;
  font-size: 12px;
}

.billing-info-wrap .checkout-account input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 5px;
  color: #181818;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .checkout-account span {
  color: #181818;
  font-weight: 400;
  margin: 0 0 0 12px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .checkout-account-toggle label {
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}

.billing-info-wrap .checkout-account-toggle input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #181818;
  font-size: 14px;
  padding: 2px 20px;
  font-family: "Proxima Nova Rg";
  width: 100%;
}

.billing-info-wrap .checkout-account-toggle input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .different-address {
  display: none;
}

.billing-info-wrap .additional-info-wrap {
  margin: 22px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #010225;
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-family: "Proxima Nova Rg";
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #181818;
  font-size: 14px;
  font-family: "Proxima Nova Rg";
  width: 100%;
  border: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3, .your-order-area .h3 {
  font-size: 20px;
  position: relative;
  margin: 0 0 45px;
  text-transform: uppercase;
  font-family: "Proxima Nova Lt";
  width: 100%;
}

.your-order-area h3:before, .your-order-area .h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #BB9B1F;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  font-size: 14px;
  padding: 0 0 7px;
  text-transform: uppercase;
  font-family: "Proxima Nova Rg";
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #BB9B1F;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
  font-family: "Proxima Nova Rg";
}

.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 14px;
  text-align: right;
  color: #181818;
  text-transform: capitalize;
  font-family: "Proxima Nova Rg";
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
  font-family: "Proxima Nova Rg";
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
  font-family: "Proxima Nova Rg";
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
  font-family: "Proxima Nova Rg";
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 15px;
  text-transform: uppercase;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
  font-family: "Proxima Nova Rg";
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}

.your-order-area .Place-order {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .your-order-area .Place-order {
    margin-top: 20px;
  }
}

.your-order-area .Place-order a {
  background-color: #BB9B1F;
  display: block;
  cursor: pointer;
  padding: 16px 50px 17px;
  text-transform: uppercase;
  width: 100%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-family: "Proxima Nova Lt";
}

.your-order-area .Place-order a:hover {
  background-color: #181818;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-main-area.pb-130 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-130 {
    padding-bottom: 70px;
  }
}

/*-------- 23. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  font-family: "Proxima Nova Lt";
  font-size: 13px;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #BB9B1F;
  border-color: #BB9B1F;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3, .myaccount-content .h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-family: "Proxima Nova Lt";
}

.myaccount-content .welcome a:hover {
  color: #BB9B1F;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #BB9B1F;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Proxima Nova Lt";
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-family: "Proxima Nova Rg";
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
  font-family: "Proxima Nova Rg";
  width: 100%;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #BB9B1F;
  text-transform: uppercase;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
  font-family: "Proxima Nova Lt";
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
  font-family: "Proxima Nova Rg";
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
  font-family: "Proxima Nova Rg";
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #BB9B1F;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
  font-family: "Proxima Nova Rg";
}

/*-------- Start My Account Page Wrapper --------*/
