/*-------- 23. My account style ---------*/

.myaccount-tab-menu {
    flex-direction: column;
    a {
        border: 1px solid #ccc;
        border-bottom: none;
        font-family: $proximanova600;
        font-size: 13px;
        display: block;
        padding: 10px 15px;
        text-transform: uppercase;
        &:last-child {
            border-bottom: 1px solid #ccc;
        }
        &:hover,
        &.active {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
        }
        i.fa {
            font-size: 14px;
            text-align: center;
            width: 25px;
        }
    }
}

#myaccountContent {
    @media #{$xs-layout} {
        margin-top: 30px;
    }
}

.myaccount-content {
    border: 1px solid #eeeeee;
    padding: 30px;
    @media #{$xs-layout} {
        padding: 20px 15px;
    }
    form {
        margin-top: -20px;
    }
    h3 {
        font-size: 20px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
        margin-bottom: 25px;
        font-family: $proximanova600;
    }
    .welcome {
        a {
            &:hover {
                color: $theme-color;
            }
        }
        strong {
            font-weight: 600;
            color: $theme-color;
        }
    }
    fieldset {
        margin-top: 20px;
        & legend {
            font-size: 16px;
            margin-bottom: 20px;
            font-family: $proximanova600;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
        }
    }
    .account-details-form {
        margin-top: 50px;
        .single-input-item {
            margin-bottom: 20px;
            label {
                font-size: 14px;
                text-transform: capitalize;
                display: block;
                margin: 0 0 5px;
                font-family: $proximanova;
            }
            input {
                border: 1px solid #e8e8e8;
                height: 50px;
                background-color: transparent;
                padding: 2px 20px;
                color: #1f2226;
                font-size: 13px;
                font-family: $proximanova;
                width: 100%;
                &:focus {
                    border: 1px solid #343538;
                }
            }
            button {
                border: none;
                background-color: $theme-color;
                text-transform: uppercase;
                padding: 9px 25px;
                color: #fff;
                font-size: 13px;
                font-family: $proximanova600;
                &:hover {
                    background-color: #1f2226;
                }
            }
        }
    }
}

.myaccount-table {
    white-space: nowrap;
    font-size: 14px;
    table,
    .table {
        th {
            padding: 10px;
            font-weight: 600;
            background-color: #f8f8f8;
            border-color: #ccc;
            border-bottom: 0;
            color: #1f2226;
            font-family: $proximanova;
        }

        td {
            padding: 10px;
            vertical-align: middle;
            border-color: #ccc;
            font-family: $proximanova;
        }
    }
}

.saved-message {
    background-color: #fff;
    border-top: 3px solid $theme-color;
    font-size: 14px;
    padding: 20px 0;
    color: #333;
    font-family: $proximanova;
}

/*-------- Start My Account Page Wrapper --------*/



