/*-------- 02. Header style ---------*/

.header-padding-tb {
    padding: 20px 0;
    @media #{$xs-layout} {
        padding: 12px 0;
    }
}

.header-padding-tb-2 {
    padding: 8px 0;
}

.language-wrap {
    ul {
        display: flex;
        li {
            margin-right: 37px;
            position: relative;
            @media #{$xs-layout} {
                margin-right: 25px;
            }
            &::before {
                content: '';
                position: absolute;
                right: -17px;
                top: 50%;
                transform: translateY(-50%) rotate(22deg);
                width: 1px;
                height: 18px;
                background: #000;
                @media #{$xs-layout} {
                    height: 12px;
                    right: -13px;
                }
            }
            &:last-child {
                margin-right: 0;
                &::before {
                    display: none;
                }
            }
            a {
                font-size: 24px;
                line-height: 1;
                display: inline-block;
                font-family: $proximanova;
                letter-spacing: 5.22px;
                @media #{$xs-layout} {
                    font-size: 17px;
                    letter-spacing: 0.22px;
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &.language-wrap-white {
        ul {
            li {
                &::before {
                    background: $white;
                }
                a {
                    color: $white;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
}

.logo {
    a {
        display: inline-block;
        img {
            max-width: 159px;
            transition: all .4s ease 0s;
            @media #{$lg-layout} {
                max-width: 130px;
            }
            @media #{$md-layout} {
                max-width: 100px;
            }
            @media #{$xs-layout} {
                max-width: 80px;
            }
        }
    }
}

.header-action-wrap {
	display: flex;
    align-items: center;
	justify-content: flex-end;
    .header-action-cart {
        margin-right: 68px;
        @media #{$lg-layout} {
            margin-right: 50px;
        }
        @media #{$md-layout} {
            margin-right: 40px;
        }
        @media #{$xs-layout} {
            margin-right: 20px;
        }
        > a {
            width: 23px;
            display: inline-block;
            position: relative;
            > .product-count {
                position: absolute;
                top: -21px;
                right: -12px;
                width: 22px;
                height: 22px;
                line-height: 22px;
                border-radius: 100%;
                font-family: $proximanova;
                font-size: 11px;
                display: inline-block;
                text-align: center;
                color: #181818;
                background-color: $white;
            }
            svg {
                width: 24px;
                @media #{$xs-layout} {
                    width: 22px;
                }
                g {
                    path {
                        stroke: #1E1E1E;
                        transition: all .3s ease 0s;
                    }
                }
            }
            &:hover {
                svg {
                    g {
                        path {
                            stroke: $theme-color;
                        }
                    }
                }
            }
            img {
                width: 100%;
            }
        }
        &.white-cart {
            > a {
                svg {
                    g {
                        path {
                            stroke: $white;
                        }
                    }
                }
                &:hover {
                    svg {
                        g {
                            path {
                                stroke: $theme-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-action-menu {
        width: 32px;
        height: 22px;
        a {
            position: relative;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: inline-block;
            > span {
                background-color: #1E1E1E;
                left: 0;
                display: block;
                position: absolute;
                transition: .4s;
                height: 2px;
                &:nth-child(2) {
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:first-child {
                    width: 16px;
                    top: 0;
                    left: auto;
                    right: 0;
                }
                &:last-child {
                    width: 16px;
                    top: 20px;
                }
            }
            &:hover {
                span {
                    background-color: $theme-color;
                    &:first-child {
                        width: 100%;
                    }
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
        &.white-menu {
            > a {
                > span {
                    background-color: $white;
                }
                &:hover {
                    span {
                        background-color: $theme-color;
                    }
                }
            }
        }
    }
}

.main-wrapper {
    .body-overlay {
        background-color: #232324;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active {
        .body-overlay {
            opacity: .7;
            visibility: visible;
            z-index: 9999;
        }
    }
} 


.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 420px;
	padding: 104px 50px 50px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 350px;
        padding: 104px 30px 50px;
    }
    @media #{$xs-layout} {
        padding: 60px 15px 50px;
        width: 280px;
    }
    .sidebar-cart-all {
        .cart-close {
            position: absolute;
            top: 37px;
            right: 50px;
            font-size: 30px;
            line-height: 30px;
            transition: all 250ms ease-out;
            @media #{$md-layout} {
                font-size: 25px;
            }
            @media #{$xs-layout} {
                top: 27px;
                right: 17px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color;
            }
        }
        .cart-content {
            > h3 {
                font-size: 20px;
                text-transform: uppercase;
                margin: 0 0 35px;
            }
            ul {
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: 310px;
                max-height: calc(100vh - 430px);
                overflow: auto;
                @media #{$md-layout} {
                    max-height: calc(100vh - 365px);
                }
                @media #{$xs-layout} {
                    max-height: calc(100vh - 360px);
                }
                li {
                    display: flex;
                    margin-bottom: 30px;
                    .cart-img {
                        flex: 0 0 70px;
                        a {
                            img {
                                width: 100%;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                    .cart-title {
                        margin-left: 20px;
                        h4 {
                            font-size: 14px;
                            margin: 0 0 8px;
                            font-family: $proximanova;
                            a {
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                        span {
                            font-size: 16px;
                            color: #8E8E8E;
                            font-family: $proximanova;
                        }
                    }
                    .cart-delete {
                        display: flex;
                        flex-grow: 100;
                        justify-content: flex-end;
                        a {
                            display: block;
                            font-size: 16px;
                            height: 20px;
                            line-height: 17px;
                            overflow: hidden;
                            width: 20px;
                            text-align: right;
                            color: $black;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
            .cart-total {
                display: block;
                width: 100%;
                margin: 27px 0 26px;
                h4 {
                    font-size: 18px;
                    text-transform: capitalize;
                    margin: 0;
                    font-family: $proximanova;
                    span {
                        font-size: 18px;
                        color: #8E8E8E;
                        font-family: $proximanova;
                        float: right;
                    }
                }
            }
            .cart-checkout-btn {
                a {
                    width: 100%;
                    display: block;
                    margin: 10px 0 0;
                    font-size: 20px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}


.wrapper-2 {
    .body-overlay-2 {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-2 .body-overlay-2 {
        opacity: 1;
        visibility: visible;
    }
} 

.off-canvas-active {
	position: fixed;
	top: 0;
	width: 370px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 1;
	transition: all 400ms ease-out;
	transform: translate(370px,0);
    background-color: #fff;
    box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$md-layout} {
        width: 320px;
        transform: translate(320px,0);
    }
    @media #{$xs-layout} {
        width: 260px;
        transform: translate(260px,0);
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    .off-canvas-close {
        position: absolute;
        left: -40px;
        top: 0px;
        z-index: 9;
        width: 40px;
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        background-color: #343538;
        i {
            transition: all .3s ease-in-out;
            display: inline-block;
            color: #fff;
            line-height: 40px;
        }
        &:hover i{
            color: $theme-color;
            transform: rotate(90deg);
        }
    }
    & .off-canvas-wrap {
        padding: 40px 30px 50px;
        overflow: auto;
        height: 100%;
        @media #{$xs-layout} {
            padding: 30px 20px 50px;
        }
    }
}


.slinky-theme-default {
	background: transparent;
    ul {
        li {
            a {
                font-family: $proximanova;
                font-size: 15px;
                position: relative;
                &:not(.back) {
                    padding: 10px 0px;
                    color: #181818;
                    &:hover {
                        color: $theme-color;
                        background: transparent;
                    }
                }
            }
        }
    }
}

.slinky-theme-default {
    .next {
        &::after {
            content: "\f105";
            font-family: 'Line Awesome Free';
            font-weight: 900;
            opacity: 1;
            transition: all .3 ease 0s;
            color: #181818;
            font-size: 15px;
            background: none;
        }
        &:hover {
            &::after {
                color: $theme-color;
            }
        }
    }
    .back {
        &::before {
            content: "\f105";
            font-family: 'Line Awesome Free';
            font-weight: 900;
            opacity: 1;
            transition: all .3 ease 0s;
            color: #fff;
            font-size: 15px;
            background: none;
            padding: 0;
            background-color: #000;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin: 0 0 13px 0px;
            border-radius: 3px;
        }
        &:hover {
            &::before {
                color: $black;
                background-color: $theme-color;
            }
        }
    }
}

.sticky-bar {
    &.stick {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        left: 0;
        transition: all .4s ease 0s;
        &.sticky-white-bg {
            box-shadow: 0 8px 20px 0 rgba(0,0,0,0.1);
            background-color: $white;
        }
    }
}

.stick {
    .logo {
        a {
            img {
                max-width: 80px;
                @media #{$xs-layout} {
                    max-width: 70px;
                }
            }
        }
    }
    &.header-padding-tb {
        padding: 10px 0;
    }
}

.header-black-bg {
    background: rgba(24, 24, 24, 0.3) none repeat scroll 0 0;
    &.stick {
        background: rgba(24, 24, 24, 0.5) none repeat scroll 0 0;
    }
}

.header-height-1 {
    height: 192px;
    @media #{$lg-layout} {
        height: 164px;
    }
    @media #{$md-layout} {
        height: 135px;
    }
    @media #{$xs-layout} {
        height: 100px;
    }
    &.stick {
        height: 96px;
        @media #{$xs-layout} {
            height: 86px;
        }
    }
}

@media #{$md-layout} {
    .section-title.mb-12 {
        margin-bottom: 40px !important;
    }
    .section-title.mb-10 {
        margin-bottom: 42px !important;
    }
}
@media #{$xs-layout} {
    .section-title.mb-12 {
        margin-bottom: 30px !important;
    }
    .section-title.mb-10 {
        margin-bottom: 32px !important;
    }
    .mb-10 {
        margin-bottom: 2.125rem !important;
    }
}



