/*-------- 04. Features style ---------*/

.features-wrap {
    .features-img {
        width: 45px;
        display: inline-block;
        transition: all 0.5s ease 0s;
        svg {
            g {
                path {
                    stroke: #1E1E1E;
                }
            }
        }
        img {
            width: 100%;
        }
    }
    h3 {
        font-size: 24px;
        margin: 29px 0 5px;
        @media #{$xs-layout} {
            margin: 20px 0 5px;
        }
    }
    p {
        font-size: 18px;
        line-height: 32px;
        margin: 0 auto;
        width: 73%;
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
        @media #{$sm-layout} {
            width: 63%;
        }
    }
    &:hover {
        .features-img {
            animation: 0.5s ease-in-out 0s normal none 1 running tada;
            svg {
                g {
                    path {
                        stroke: $theme-color;
                    }
                }
            }
        }
    }
}

.features-all-wrap {
	margin: 0 -65px;
    @media #{$xx-layout} {
        margin: 0 0px;
    }
    @media #{$xl-layout} {
        margin: 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

































































