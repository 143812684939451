/*-------- 03. Slider style ---------*/

.slider-height-1 {
    height: 1080px;
    @media #{$xx-layout} {
        height: 774px;
    }
    @media #{$xl-layout} {
        height: 680px;
    }
    @media #{$lg-layout} {
        height: 650px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 550px;
    }
}

.single-slider-wrap {
    position: relative;
    .slider-img {
        position: absolute;
        top: 0;
        right: 0;
        width: 70.7%;
        height: 100%;
        @media #{$lg-layout} {
            width: 82%;
        }
        @media #{$md-layout} {
            width: 90%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.slider-content {
    position: relative;
    z-index: 9;
    margin: 0 0 0 300px;
    @media #{$xx-layout} {
        margin: 0 0 0 50px;
    }
    @media #{$xl-layout} {
        margin: 0 0 0 15px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 30px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 40px;
    }
    @media #{$xs-layout} {
        margin: 0 15px 0 15px;
        text-align: center;
        width: 100%;
    }
    h3 {
        font-size: 20px;
        color: #4D4D4D;
        font-family: $proximanova;
        letter-spacing: 8.33px;
        margin: 0;
        @media #{$xl-layout} {
            font-size: 18px;
        }
        @media #{$lg-layout} {
            font-size: 18px;
            letter-spacing: 6.33px;
        }
        @media #{$md-layout} {
            font-size: 18px;
            letter-spacing: 4.33px;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            letter-spacing: 4.33px;
            color: $white;
        }
    }
    h1 {
        color: $theme-color;
        font-size: 138px;
        line-height: .9;
        margin: 24px 0 28px;
        @media #{$xx-layout} {
            font-size: 120px;
            margin: 18px 0 20px;
        }
        @media #{$xl-layout} {
            font-size: 100px;
            margin: 15px 0 20px;
        }
        @media #{$lg-layout} {
            font-size: 90px;
            margin: 15px 0 20px;
        }
        @media #{$md-layout} {
            font-size: 70px;
            margin: 15px 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 55px;
            margin: 15px 0 20px;
        }
    }
    &.slider-content-red {
        h3 {
            color: #F46C7D;
        }
        h1 {
            font-size: 250px;
            color: #E13636;
            line-height: 235px;
            letter-spacing: -8.93px;
            margin: -33px 0 0px;
            @media #{$xx-layout} {
                line-height: 180px;
                font-size: 170px;
            }
            @media #{$xl-layout} {
                line-height: 145px;
                font-size: 140px;
                margin: -23px 0 0px;
            }
            @media #{$lg-layout} {
                line-height: 145px;
                font-size: 140px;
                margin: -23px 0 0px;
            }
            @media #{$md-layout} {
                line-height: 125px;
                font-size: 120px;
                margin: -10px 0 0px;
            }
            @media #{$xs-layout} {
                line-height: 90px;
                font-size: 80px;
                margin: 0px 0 0px;
                letter-spacing: -3.93px;
            }
        }
        &.slider-content {
            margin: 151px 0 0 300px;
            @media #{$xx-layout} {
                margin: 151px 0 0 50px;
            }
            @media #{$xl-layout} {
                margin: 135px 0 0 15px;
            }
            @media #{$lg-layout} {
                margin: 80px 0 0 15px;
            }
            @media #{$md-layout} {
                margin: 80px 0 0 40px;
            }
            @media #{$xs-layout} {
                margin: 0 15px 0 15px;
                text-align: center;
                width: 100%;
            }
        }
    }
}

.btn-style {
    a {
        &.btn {
            font-family: $proximanova;
            @media #{$xl-layout} {
                padding: 17px 50px 17px;
            }
            @media #{$lg-layout} {
                padding: 17px 50px 17px;
            }
            @media #{$md-layout} {
                padding: 15px 40px 15px;
            }
            @media #{$xs-layout} {
                padding: 14px 30px 15px;
                font-size: 18px;
                &.slider-btn {
                    color: $white;
                }
            }
            &.btn-padding-2 {
                padding: 17px 25px;
                @media #{$xs-layout} {
                    padding: 10px 25px 11px;
                }
            }
        }
        &.btn-outline-primary {
            border-color: #acacac;
            &:focus {
                box-shadow: none;
            }
            &:active{
                box-shadow: none;
            }
            &:hover {
                border-color: $primary;
            }
        }
    }
}

.single-animation-wrap {
    &.slick-active {
        .slider-animated-1 {
            h3 {
                animation-delay: 1.0s;
                animation-name: fadeInLeft;
            }
            h1 {
                animation-delay: 1.5s;
                animation-name: fadeInLeft;
            }
            a {
                animation-delay: 1.9s;
                animation-name: fadeInLeft;
            }
            &.slider-img {
                img {
                    animation-delay: 1.0s;
                    animation-name: fadeInRight;
                }
            }
        }
    }
}

.nav-style-1 {
    > span {
        position: absolute;
        bottom: 10.5%;
        left: 15.8%;
        font-size: 15px;
        color: #000000;
        width: 47px;
        line-height: 46px;
        height: 47px;
        border-radius: 100%;
        border: 1px solid #979797;
        text-align: center;
        z-index: 9;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        @media #{$xx-layout} {
            left: 3.8%;
        }
        @media #{$xl-layout} {
            left: 1.2%;
        }
        @media #{$lg-layout} {
            left: 3%;
        }
        @media #{$md-layout} {
            left: 5.2%;
        }
        @media #{$xs-layout} {
            left: 50%;
            transform: translateX(-50%);
        }
        svg {
            path {
                stroke: #1E1E1E;
                transition: all .3s ease 0s;
            }
        }
        &.slider-icon-next {
            margin-left: 76px;
            @media #{$xs-layout} {
                margin-left: 30px;
            }
        }
        @media #{$xs-layout} {
            &.slider-icon-prev {
                margin-left: -30px;
            }
        }
        &:hover {
            svg {
                path {
                    stroke: #fff;
                }
            }
            background-color: $theme-color;
            border: 1px solid $theme-color;
        }
    }
    &.nav-style-1-modify {
        > span {
            color: #1E1E1E;
            background-color: $white;
            border: 1px solid $white;
            &:hover {
                background-color: #E13636;
                border: 1px solid #E13636;
            }
        }
    }
}





