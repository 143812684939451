// Custom variable file only for customer to use
$proximanova: 'Proxima Nova Rg';
$proximanova600: 'Proxima Nova Lt';
$proximanovathin: 'Proxima Nova Th';
$theme-color: #BB9B1F;
$white: #ffffff;














































