/*-------- 24. Login register style ---------*/

.sidebar-widget {
    .sidebar-widget-title {
        color: #BB9B1F;
        letter-spacing: 1px;
        font-size: 22px;
        text-transform: uppercase;
        font-family: $proximanova;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 18px;
        }
    }
    .sidebar-widget-title2 {
        font-size: 22px;
        color: #1D1D1D;
        letter-spacing: 2.15px;
        text-transform: uppercase;
        font-family: $proximanova;
    }
    .shop-category {
        margin-top: 40px;
        @media #{$xs-layout} {
            margin-top: 25px;
        }
        ul {
            li {
                display: block;
                margin: 0 0 15px;
                border-bottom: 1px solid #DEDEDE;
                padding-bottom: 14px;
                &:last-child {
                    margin: 0 0 0;
                    border-bottom: none;
                    padding-bottom: 0;
                }
                a {
                    font-size: 18px;
                    color: #6D6D6D;
                    display: block;
                    font-family: $proximanova;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    .price-filter {
        margin-top: 44px;
        .price-slider-amount {
            input {
                background: transparent;
                padding: 0;
                border: none;
                font-size: 15px;
                font-weight: 500;
            }
        }
        #slider-range {
            background: #dbdbdb none repeat scroll 0 0;
            border: medium none;
            border-radius: 0px;
            height: 5px;
            margin-bottom: 12px;
            margin-left: auto;
            margin-top: 5px;
            span {
                transition: all .0s ease 0s;
            }
            .ui-slider-range {
                position: absolute;
                display: block;
                border: 0;
                background: #181818 none repeat scroll 0 0;
                border: none;
                transition: all .0s ease 0s;
            }
            .ui-slider-handle {
                background: $white none repeat scroll 0 0;
                border: medium none;
                border-radius: 50%;
                height: 20px;
                margin-left: 0;
                width: 20px;
                margin-top: -3px;
                border: 1px solid #979797;
            }
        }
        .price-slider-amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 34px 0 0;
            .label-input {
                display: flex;
                > span {
                    color: #1D1D1D;
                    font-size: 12px;
                    display: inline-block;
                    margin-right: 3px;
                    font-family: $proximanova;
                }
                input {
                    color: #1D1D1D;
                    height: auto;
                    width: 65px;
                    font-size: 12px;
                    font-family: $proximanova;
                    &::-moz-input-placeholder {
                        color: #1D1D1D;
                        opacity: 1;
                    }
                    &::-webkit-input-placeholder {
                        color: #1D1D1D;
                        opacity: 1;
                    }
                }
            }
            button {
                color: #1D1D1D;
                line-height: 1;
                padding: 13px 36px 12px;
                cursor: pointer;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                font-family: $proximanova;
                border: 2px solid #E3E3E3;
                background-color: transparent;
                transition: all .3s ease 0s;
                @media #{$xl-layout} {
                    padding: 10px 25px;
                }
                @media #{$lg-layout} {
                    padding: 10px 15px;
                }
                &:hover {
                    background-color: $theme-color;
                    border: 2px solid $theme-color;
                    color: $white;
                }
            }
        }
    }
    .sidebar-widget-list {
        margin-top: 43px;
        @media #{$xs-layout} {
            margin-top: 28px;
        }
        ul {
            li {
                align-items: center;
                position: relative;
                padding: 0px 0 15px;
                &:last-child {
                    padding: 0px 0 0px;
                }
                .sidebar-widget-list-left {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        z-index: 999;
                        width: 16px;
                        height: 16px;
                        top: 2px;
                        left: 0;
                        &:checked ~ .checkmark {
                            background-color: $theme-color;
                            border: 1px solid $theme-color;
                        }
                        &:checked ~ .checkmark::after {
                            display: block;
                        }
                    }
                    .checkmark {
                        position: absolute;
                        top: 3px;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background-color: #fff;
                        border: 1px solid #E0E0E0;
                        border-radius: 0px;
                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                        &::after {
                            left: 5px;
                            top: 2px;
                            width: 5px;
                            height: 8px;
                            border: solid white;
                            border-top-width: medium;
                            border-right-width: medium;
                            border-bottom-width: medium;
                            border-left-width: medium;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
                a {
                    margin-left: 37px;
                    display: inline-block;
                    line-height: 1;
                    color: #6D6D6D;
                    font-size: 12px;
                    font-family: $proximanova;
                    text-transform: uppercase;
                    &:hover {
                        color: #181818;
                        border-bottom: 1px solid #181818;
                    }
                }
                
            }
        }
    }
    .sidebar-widget-size {
        margin-top: 37px;
        @media #{$xs-layout} {
            margin-top: 30px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                margin-right: 10px;
                margin-bottom: 10px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    width: 55px;
                    height: 45px;
                    font-size: 12px;
                    font-family: $proximanova;
                    color: #181818;
                    border: 1px solid #E6E6E6;
                    display: inline-block;
                    text-transform: uppercase;
                    text-align: center;
                    line-height: 45px;
                    &:hover {
                        color: $white;
                        background-color: $theme-color;
                        border: 1px solid $theme-color;
                    }
                }
            }
        }
    }
    .sidebar-widget-color {
        margin-top: 22px;
        @media #{$xs-layout} {
            margin-top: 15px;
        }
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                margin-right: 16px;
                margin-bottom: 5px;
                display: inline-block;
                @media #{$lg-layout} {
                    margin-right: 12px;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    width: 25px;
                    height: 25px;
                    font-size: 0;
                    display: inline-block;
                    border-radius: 50%;
                    position: relative;
                    &.white {
                        background-color: #ffffff;
                        border: 1px solid #979797;
                    }
                    &.pink {
                        background-color: #F46C7D;
                    }
                    &.yellow {
                        background-color: #BB9B1F;
                    }
                    &.black {
                        background-color: #4D4D4D;
                    }
                    &.blue {
                        background-color: #6CB8F4;
                    }
                }
            }
        }
    }
    .sidebar-widget-tag {
        margin-top: 38px;
        @media #{$xs-layout} {
            margin-top: 28px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                margin: 0 10px 10px 0px;
                a {
                    display: inline-block;
                    border: 1px solid #E6E6E6;
                    color: #181818;
                    line-height: 1;
                    padding: 15px 15px;
                    font-family: $proximanova;
                    &:hover {
                        color: $white;
                        border: 1px solid $theme-color;
                        background-color: $theme-color;
                    }
                }
            }
        }
    }
    .sidebar-search {
        .sidebar-search-form {
            position: relative;
            input {
                border: 1px solid #CCCCCC;
                width: 100%;
                height: 60px;
                color: #B5B5B5;
                padding: 2px 50px 2px 20px;
                font-family: $proximanova;
            }
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                border: none;
                padding: 0;
                background-color: transparent;
                transition: all .3s ease 0s;
                svg {
                    g {
                        path {
                            stroke: #1E1E1E;
                            transition: all .3s ease 0s;
                        }
                    }
                }
                &:hover {
                    svg {
                        g {
                            path {
                                stroke: $theme-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-category {
        margin: 47px 0 0;
        ul {
            li {
                margin: 0 0 26px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:last-child {
                    margin: 0 0 0;
                }
                a {
                    display: inline-block;
                    line-height: 1;
                    font-family: $proximanova;
                    color: #181818;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                span {
                    color: #6D6D6D;
                    font-family: $proximanova;
                }
            }
        }
    }
    .sidebar-blog-wrap {
        margin: 28px 0 0;
        .single-sidebar-blog {
            display: flex;
            border-bottom: 1px solid #DCDCDC;
            margin-bottom: 30px;
            padding-bottom: 30px;
            &:last-child {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
            .sidebar-blog-img {
                flex: 0 0 100px;
                overflow: hidden;
                @media #{$lg-layout} {
                    flex: 0 0 80px;
                }
                a {
                    display: inline-block;
                    img {
                        width: 100%;
                        transition: all .4s ease 0s;
                    }
                }
            }
            .sidebar-blog-content {
                margin: 12px 0 0 21px;
                h3 {
                    font-size: 16px;
                    font-family: $proximanova;
                    margin: 0 0 6px;
                    a {
                        color: #2F2F2F;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
                span {
                    color: #6D6D6D;
                    font-size: 10px;
                    display: inline-block;
                    font-family: $proximanova;
                }
            }
            &:hover {
                .sidebar-blog-img {
                    a {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    .sidebar-archive-wrap {
        margin: 30px 0 0;
        select {
            color: #3E3E3E;
            font-family: $proximanova;
            border: 1px solid #D4D4D4;
            width: 100%;
            -moz-appearance: none;
            -webkit-appearance: none;
            padding: 2px 50px 0 19px;
            height: 50px;
            cursor: pointer;
            background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-down-2.svg") no-repeat scroll right 20px center;
        }
    }
    .sidebar-widget-tag-2 {
        margin: 36px 0 0;
        ul {
            li {
                display: inline-block;
                margin-right: 3px;
                margin-bottom: 9px;
                a {
                    color: #535353;
                    font-family: $proximanova;
                    &:hover {
                        color: $theme-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .subscribe-form-2 {
        .subscribe-form-style-2 {
            .mc-form-2 {
                position: relative;
                input {
                    border: 1px solid #262626;
                    height: 70px;
                    color: #181818;
                    font-family: $proximanova;
                    padding: 2px 50px 2px 30px;
                    width: 100%;
                    background-color: transparent;
                    &::-moz-input-placeholder {
                        color: #181818;
                        opacity: 1;
                    }
                    &::-webkit-input-placeholder {
                        color: #181818;
                        opacity: 1;
                    }
                }
                .mc-news-2 {
                    display: none;
                }
                .clear-2 {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    input {
                        font-size: 0px;
                        border: none;
                        padding: 5px 28px;
                        background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/mail.svg") no-repeat scroll right 20px center;
                    }
                }
            }
        }
    }
}

.sidebar-widget-instagram {
	margin-top: 37px;
    &.instagram-mrg-dec {
        margin-top: 28px;
    }
    @media #{$xs-layout} {
        margin-top: 28px;
    }
}

.padding-20-row-col {
    .row {
        margin-right: -10px;
        margin-left: -10px;
        div[class^="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
.shop-page-wrap {
	margin: 0 0 0 23px;
    &.margin-none {
        margin: 0 0 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0 0 0px;
    }
}
.sidebar-wrapper-mr {
    margin-right: 37px;
    @media #{$xl-layout} {
        margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
        margin-top: 70px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 50px;
    }
}

@media #{$md-layout} {
    .sidebar-mrg-responsive {
        margin-top: 50px;
    }
}

@media #{$xs-layout} {
    .sidebar-mrg-responsive {
        margin-top: 50px;
    }
}



